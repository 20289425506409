import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardGroup,
  CardHeader,
  CardSubtitle,
  Button,
} from "reactstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import axios from "axios";

import ConfirmationModal from "./Modal/ConfirmationModal";
import googleCalendarImg from "../../assets/images/googleCalendarImg.png";
import googleCalendar from "../../assets/images/googleCalendar.png";
import chrome from "../../assets/images/chrome.png";
import s2 from "../../assets/images/s2.png";
import adversus from "../../assets/images/adversus.png";
import upSalesImg from "../../assets/images/upSalesImg.png";
import pipedriveImg from "../../assets/images/pipedriveImg.png";
import hubspotImg from "../../assets/images/hubspotImg.png";
import zohoImg from "../../assets/images/zohoImg.png";
import outlookImg from "../../assets/images/outlookImg.png";
import outlook from "../../assets/images/outlook.png";
import p from "../../assets/images/p.png";
import salesforce from "../../assets/images/salesforce.png";
import telink from "../../assets/images/telinkLogo.png";
import telinkLogo from "../../assets/images/telinkLogoSmall.png";
import telavox from "../../assets/images/telavox.png";
import telavoxLogo from "../../assets/images/telavox-small.png";
import dynamics365 from "../../assets/images/dynamics365.png";
import dynamics365Logo from "../../assets/images/dynamics365-small.png";
import crmServiceLogo from "../../assets/images/crmServiceLogo.png";
import crmService from "../../assets/images/crmService.png";
import { urls } from "../../utilities/urls";
import {
  FinishedIntegration,
  IntegrationType,
} from "../../store/settings/Integration/interface";
import SwitchComponent from "../../components/SwitchButton/SwitchComponent";
import { CallTokenType, Icurrentaccountselected } from "../../store/interface";
import { CALL_TOKENS } from "./CallImport/utils/constants";

type IntegrationsProfileProps = RouteComponentProps & {
  finishedIntegrations: FinishedIntegration[];
  isUserRightLevel: boolean;
  currentaccountselected: Icurrentaccountselected;
  isLynesEnabled: boolean;
  isTelavoxEnabled: boolean;
  isCRMServiceEnabled: boolean;
  areAllTokensDisabled: boolean;
  disableLynes: (accountId: String) => void
  enableLynes: (accountId: String) => void;
  disableTelavox: () => void;
  handleOpenIntegrationModal: (
    modalType: "google_calendar" | "chrome" | "outlook" | "lynes" | "telavox" | "zoho" | "crm-service" | "dynamics365"
  ) => void;
  onRemoveIntegration: (integrationType: IntegrationType) => void;
  onUpdateIntegration: (
    integration: FinishedIntegration,
    importCalls: boolean
  ) => void;
  disableCallIntegration?: (token: string) => void;
  enableCallIntegration?: (token: CallTokenType) => void;
  disableCRMService: () => void;
};

interface IntegrationsProfileState {
  title: string;
  isModalOpen: boolean;
}

export const INTEGRATION = {
  GOOGLE_MEET: "googleMeet",
  MICROSOFT_TEAMS: "msTeams",
  HUBSPOT: "hubspot",
  PIPEDRIVE: "pipedrive",
  SALESFORCE: "salesforce",
  GOOGLE_CHROME_EXTENSION: "googleChromeExtension",
  ADVERSUS: "adversus",
  S2: "s2",
  ZOHO: "zoho",
  CRM_SERVICE: "crm-service",
  DYNAMICS_365: 'dynamics365',
};

class IntegrationsProfile extends Component<
  IntegrationsProfileProps,
  IntegrationsProfileState
> {
  constructor(props: IntegrationsProfileProps) {
    super(props);
    this.state = {
      title: "",
      isModalOpen: false,
    };
  }

  handleHubspotClick(event: any) {
    event.preventDefault();
    const redirectUrl = `${urls.baseApiURL}hubspot/login`;

    axios.get(redirectUrl).then((response) => {
      const responseUrl = response?.data?.url;
      if (responseUrl) {
        window.open(responseUrl, "_blank");
      }
    });
  }

  handleZohoClick(event: any) {
    event.preventDefault();
    const redirectUrl = `${urls.baseApiURL}zoho/login`;

    axios.get(redirectUrl).then((response) => {
      const responseUrl = response?.data?.url;
      if (responseUrl) {
        window.open(responseUrl, "_blank");
      }
    });
  }

  handleDynamicsClick = (event: any) => {
    const { handleOpenIntegrationModal } = this.props;

    handleOpenIntegrationModal('dynamics365');
  }

  handleGoogleMeetClick(event: any) {
    event.stopPropagation();
    event.preventDefault();
    const redirectUrl = `${urls.baseApiURL}google-meet/login`;

    axios.get(redirectUrl).then((response) => {
      const responseUrl = response?.data?.url;
      if (responseUrl) {
        window.open(responseUrl, "_blank");
      }
    });
  }

  handleMicrosoftTeamsClick(event: any) {
    event.preventDefault();
    const redirectUrl = `${urls.baseApiURL}ms-teams/login`;

    axios.get(redirectUrl).then((response) => {
      const responseUrl = response?.data?.url;
      if (responseUrl) {
        window.open(responseUrl, "_blank");
      }
    });
  }

  handleAdversusAndS2Click = () => {
    const { history } = this.props;

    history.push("/settings/call-import");
  };

  handleSalesforceClick = () => {
    const authUrl = `${urls.baseApiURL}salesforce/auth-url`;

    axios.get(authUrl).then((response) => {
      const responseUrl = response?.data?.url;
      if (responseUrl) {
        window.open(responseUrl, "_blank");
      }
    });
  }

  findIntegrationByType = (
    finishedIntegrations: FinishedIntegration[],
    integrationType: string
  ): FinishedIntegration | undefined => {
    return finishedIntegrations.find(
      (integration) => integration.type === integrationType
    );
  };

  canImportCalls(integration: string) {
    return (
      integration === INTEGRATION.PIPEDRIVE ||
      integration === INTEGRATION.HUBSPOT ||
      integration === INTEGRATION.SALESFORCE
    );
  }

  disableAdversusOrS2 = () => {
    const { disableCallIntegration } = this.props;
    const { title } = this.state;

    disableCallIntegration && disableCallIntegration(title);
    this.closeModal();
  };

  openModal = (title: string) => {
    this.setState({ isModalOpen: true, title: title });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false, title: "" });
  };

  enable = async (token: CallTokenType) => {
    const { enableCallIntegration } = this.props;

    enableCallIntegration && enableCallIntegration(token);
    this.closeModal();
  };

  enableLynes = async () => {
    const { currentaccountselected, enableLynes, handleOpenIntegrationModal } = this.props;

    enableLynes(currentaccountselected.id);
    handleOpenIntegrationModal('lynes');
  }

  disableLynes = async () => {
    const { currentaccountselected, disableLynes } = this.props;

    disableLynes(currentaccountselected.id);
    this.closeModal();
  }

  enableTelavox = async () => {
    const { handleOpenIntegrationModal } = this.props;

    handleOpenIntegrationModal('telavox');
  }

  disableTelavox = async () => {
    const { disableTelavox } = this.props;

    disableTelavox();
    this.closeModal();
  }

  enableCRMService = async () => {
    const { handleOpenIntegrationModal } = this.props;

    handleOpenIntegrationModal('crm-service');
  }

  disableCRMService = async () => {
    const { disableCRMService } = this.props;

    disableCRMService();
    this.closeModal();
  }

  render() {
    const {
      finishedIntegrations,
      isUserRightLevel,
      currentaccountselected,
      isLynesEnabled,
      isTelavoxEnabled,
      handleOpenIntegrationModal,
      onRemoveIntegration,
      onUpdateIntegration,
    } = this.props;
    const { isModalOpen, title } = this.state;

    const googleMeetIntegration = this.findIntegrationByType(
      finishedIntegrations,
      INTEGRATION.GOOGLE_MEET
    );

    const msTeamsIntegration = this.findIntegrationByType(
      finishedIntegrations,
      INTEGRATION.MICROSOFT_TEAMS
    );

    const hubspotIntegration = this.findIntegrationByType(
      finishedIntegrations,
      INTEGRATION.HUBSPOT
    );

    const pipedriveIntegration = this.findIntegrationByType(
      finishedIntegrations,
      INTEGRATION.PIPEDRIVE
    );

    const googleChromeExtensionIntegration = this.findIntegrationByType(
      finishedIntegrations,
      INTEGRATION.GOOGLE_CHROME_EXTENSION
    );

    const salesforceIntegration = this.findIntegrationByType(
      finishedIntegrations,
      INTEGRATION.SALESFORCE
    );

    const adversusIntegration = this.findIntegrationByType(
      finishedIntegrations,
      INTEGRATION.ADVERSUS
    );

    const s2Integration = this.findIntegrationByType(
      finishedIntegrations,
      INTEGRATION.S2
    );

    const zohoIntegration = this.findIntegrationByType(
      finishedIntegrations,
      INTEGRATION.ZOHO
    );

    const dynamicsIntegration = this.findIntegrationByType(
      finishedIntegrations,
      INTEGRATION.DYNAMICS_365
    );

    const importCallsLabel = "Import calls from integration";

    const importCallsPlaceholderComponent = (
      <div
        style={{
          paddingTop: "35px",
          width: "100%",
        }}
      />
    );

    const isMeetricEngageEnabled = currentaccountselected.is_meetric_engage_enabled === true;

    return (
      <>
        <CardGroup className="row integration-cards">
          <div>
            <Card className="card_integration">
              <CardHeader>
                <img src={googleCalendarImg} alt="loader" height="20" />
                <span>Google Calendar Notetaker</span>
                <hr />
                <CardSubtitle>
                  Allow our Meeting Notetaker AI bot to join meetings created in
                  Google Calendar
                </CardSubtitle>
              </CardHeader>
              <CardBody
                onClick={this.handleGoogleMeetClick}
                style={{ cursor: "pointer" }}
                className='d-flex justify-content-center flex-column items-aling-center'
              >
                <div>
                  <img
                    src={googleCalendar}
                    alt="loader"
                    width="180"
                    height="130"
                  />
                </div>
                {googleMeetIntegration && (
                  <div className="finished-integration">
                    <div className="finished-integration-icon">
                      <i className="fa fa-check-circle" />
                    </div>
                    <div
                      className="finished-integration-disconnect"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onRemoveIntegration("googleMeet");
                      }}
                    >
                      <span>Disconnect</span>
                    </div>
                  </div>
                )}
              </CardBody>
              <CardFooter>{importCallsPlaceholderComponent}</CardFooter>
            </Card>
          </div>
          <div>
            <Card className="card_integration">
              <CardHeader>
                <img src={outlookImg} alt="loader" height="20" />
                <span>Outlook Notetaker</span>
                <hr />
                <CardSubtitle>
                  Allow our Meeting Notetaker AI bot to join meetings created in
                  Outlook Calendar
                </CardSubtitle>
              </CardHeader>
              <CardBody
                onClick={this.handleMicrosoftTeamsClick}
                style={{ cursor: "pointer" }}
                className='d-flex justify-content-center flex-column items-aling-center'
              >
                <div>
                  <img src={outlook} alt="loader" width="180" />
                </div>
                {msTeamsIntegration && (
                  <div className="finished-integration">
                    <div className="finished-integration-icon">
                      <i className="fa fa-check-circle" />
                    </div>
                    <div
                      className="finished-integration-disconnect"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onRemoveIntegration("msTeams");
                      }}
                    >
                      <span>Disconnect</span>
                    </div>
                  </div>
                )}
              </CardBody>
              <CardFooter>{importCallsPlaceholderComponent}</CardFooter>
            </Card>
          </div>
          <div>
            <Card className="card_integration">
              <CardHeader>
                <img src={hubspotImg} alt="loader" height="20" />
                <span>Native Hubspot Integration</span>
                <hr />
                <CardSubtitle>
                  When meeting is done, push meeting data to Hubspot
                </CardSubtitle>
              </CardHeader>
              <CardBody
                onClick={this.handleHubspotClick}
                style={{ cursor: "pointer" }}
                className='d-flex justify-content-center flex-column items-aling-center'
              >
                <div>
                  <img
                    src={hubspotImg}
                    alt="loader"
                    height="40"
                  />
                </div>
                {hubspotIntegration && (
                  <div className="finished-integration">
                    <div className="finished-integration-icon">
                      <i className="fa fa-check-circle" />
                    </div>
                    <div
                      className="finished-integration-disconnect"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onRemoveIntegration("hubspot");
                      }}
                    >
                      <span>Disconnect</span>
                    </div>
                  </div>
                )}
              </CardBody>
              <CardFooter>
                {hubspotIntegration &&
                this.canImportCalls(INTEGRATION.HUBSPOT) ? (
                  <div className="card_integration-switch">
                    <SwitchComponent
                      label={importCallsLabel}
                      checked={hubspotIntegration.importCalls}
                      onChange={() =>
                        onUpdateIntegration(
                          hubspotIntegration,
                          !hubspotIntegration?.importCalls
                        )
                      }
                    />
                  </div>
                ) : (
                  importCallsPlaceholderComponent
                )}
              </CardFooter>
            </Card>
          </div>
          <div>
            <Card className="card_integration">
              <CardHeader>
                <img src={p} alt="loader" height="20" />
                <span>Native Pipedrive Integration</span>
                <hr />
                <CardSubtitle>
                  When meeting is done, push meeting data to Pipedrive
                </CardSubtitle>
              </CardHeader>
              <CardBody
                style={{ cursor: "pointer" }}
                className='d-flex justify-content-center flex-column items-aling-center'
              >
                <a
                  href="https://www.pipedrive.com/en/marketplace/app/pitchflow-video-conference/1ec7dcace982ab81"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>
                    <img
                      src={pipedriveImg}
                      alt="loader"
                      height="40"
                    />
                  </div>
                  {pipedriveIntegration && (
                    <div className="finished-integration">
                      <div className="finished-integration-icon">
                        <i className="fa fa-check-circle" />
                      </div>
                      <div
                        className="finished-integration-disconnect"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onRemoveIntegration("pipedrive");
                        }}
                      >
                        <span>Disconnect</span>
                      </div>
                    </div>
                  )}
                </a>
              </CardBody>
              <CardFooter>
                {pipedriveIntegration &&
                this.canImportCalls(INTEGRATION.PIPEDRIVE) ? (
                  <div className="card_integration-switch">
                    <SwitchComponent
                      label={importCallsLabel}
                      checked={pipedriveIntegration.importCalls}
                      onChange={() =>
                        onUpdateIntegration(
                          pipedriveIntegration,
                          !pipedriveIntegration?.importCalls
                        )
                      }
                    />
                  </div>
                ) : (
                  importCallsPlaceholderComponent
                )}
              </CardFooter>
            </Card>
          </div>
          <div>
            <Card className="card_integration">
              <CardHeader>
                <span>Native Salesforce Integration</span>
                <hr />
                <CardSubtitle>
                  When meeting is done, push meeting data to Salesforce. This integration is done by our support team.
                </CardSubtitle>
              </CardHeader>
              <CardBody
                className="d-flex justify-content-center flex-column items-aling-center"
                style={{ cursor: "pointer" }}
                onClick={this.handleSalesforceClick}
              >
                <div>
                  <img
                    src={salesforce}
                    alt="loader"
                    height="80"
                  />
                </div>
                {salesforceIntegration && (
                  <div className="finished-integration">
                    <div className="finished-integration-icon">
                      <i className="fa fa-check-circle" />
                    </div>
                    <div
                      className="finished-integration-disconnect"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onRemoveIntegration("salesforce");
                      }}
                    >
                      <span>Disconnect</span>
                    </div>
                  </div>
                )}
              </CardBody>
              <CardFooter>
                {importCallsPlaceholderComponent}
              </CardFooter>
            </Card>
          </div>
          {isMeetricEngageEnabled && (
            <div>
            <Card className="card_integration">
              <CardHeader>
                <img src={googleCalendarImg} alt="loader" height="20" />
                <span>Google Calendar Conference Integration</span>
                <hr />
                <CardSubtitle>
                  Add professional Meetric links to Google Calendar
                </CardSubtitle>
              </CardHeader>
              <CardBody
                onClick={() => {
                  handleOpenIntegrationModal("google_calendar");
                }}
                style={{ cursor: "pointer" }}
                className='d-flex justify-content-center flex-column items-aling-center'
              >
                <div>
                  <img
                    src={googleCalendar}
                    alt="loader"
                    width="180"
                    height="130"
                  />
                </div>
                {googleChromeExtensionIntegration && (
                  <div className="finished-integration">
                    <div className="finished-integration-icon">
                      <i className="fa fa-check-circle" />
                    </div>
                  </div>
                )}
              </CardBody>
              <CardFooter>{importCallsPlaceholderComponent}</CardFooter>
            </Card>
          </div>
          )}
          {isMeetricEngageEnabled && (
            <>
              <div>
                <Card className="card_integration">
                  <CardHeader>
                    <img src={outlookImg} alt="loader" height="20" />
                    <span>Outlook Conference Integration</span>
                    <hr />
                    <CardSubtitle>
                      Add professional Meetric links to Outlook
                    </CardSubtitle>
                  </CardHeader>
                  <CardBody
                    onClick={() => {
                      handleOpenIntegrationModal('outlook');
                    }}
                    style={{ cursor: 'pointer' }}
                    className="d-flex justify-content-center flex-column items-aling-center"
                  >
                    <div>
                      <img src={outlook} alt="loader" height="120" />
                    </div>
                  </CardBody>
                  <CardFooter>{importCallsPlaceholderComponent}</CardFooter>
                </Card>
              </div>
              <div>
                <Card className="card_integration">
                  <CardHeader>
                    <img src={chrome} alt="loader" height="20" />
                    <span>Chrome Integration</span>
                    <hr />
                    <CardSubtitle>
                      Easily add Meetric meetings to your CRM or calendar
                    </CardSubtitle>
                  </CardHeader>
                  <CardBody
                    onClick={() => {
                      handleOpenIntegrationModal('chrome');
                    }}
                    style={{ cursor: 'pointer' }}
                    className="d-flex justify-content-center flex-column items-aling-center"
                  >
                    <div className="images chrome-extension">
                      <img src={googleCalendar} alt="chrome" height="75" />
                      <img
                        src={upSalesImg}
                        alt="chrome"
                        height="24"
                        className="upsales-img"
                      />
                      <img src={pipedriveImg} alt="chrome" height="32" />
                      <img src={hubspotImg} alt="chrome" height="32" />
                    </div>
                  </CardBody>
                  <CardFooter>{importCallsPlaceholderComponent}</CardFooter>
                </Card>
              </div>
            </>
          )}

          {isUserRightLevel && (
          <>
            <div>
              <Card className="card_integration">
                <CardHeader>
                  <img src={adversus} alt="loader" height="20" />
                  <span>Adversus</span>
                  <hr />
                  <CardSubtitle>
                    Enable/Disable Adversus integration on this account. If
                    enabled you can define settings for importing calls from
                    Adversus integration.
                  </CardSubtitle>
                </CardHeader>
                <CardBody
                  onClick={this.handleAdversusAndS2Click}
                  style={{ cursor: "pointer" }}
                  className='d-flex justify-content-center flex-column items-aling-center'
                >
                  <div title="Go to Adversus settings">
                    <img src={adversus} alt="loader" width="100" />
                  </div>
                  {adversusIntegration && (
                    <div className="finished-integration">
                      <div className="finished-integration-icon">
                        <i className="fa fa-check-circle" />
                      </div>
                    </div>
                  )}
                </CardBody>
                <CardFooter className="integration-buttons">
                  {currentaccountselected.call_integrations?.includes(
                    CALL_TOKENS.adverus
                  ) ? (
                    <Button onClick={() => this.openModal(CALL_TOKENS.adverus)}>
                      Disable
                    </Button>
                  ) : (
                    <Button onClick={() => this.enable(CALL_TOKENS.adverus)}>
                      Enable
                    </Button>
                  )}
                </CardFooter>
              </Card>
            </div>
            <div>
              <Card className="card_integration">
                <CardHeader>
                  <img src={s2} alt="loader" height="20" />
                  <span>S2</span>
                  <hr />
                  <CardSubtitle>
                    Enable/Disable S2 integration on this account. If enabled
                    you can define settings for importing calls from S2
                    integration.
                  </CardSubtitle>
                </CardHeader>
                <CardBody
                  onClick={this.handleAdversusAndS2Click}
                  style={{ cursor: "pointer" }}
                  className='d-flex justify-content-center flex-column items-aling-center'
                >
                  <div title="Go to S2 settings">
                    <img src={s2} alt="loader" width="100" />
                  </div>
                  {s2Integration && (
                    <div className="finished-integration">
                      <div className="finished-integration-icon">
                        <i className="fa fa-check-circle" />
                      </div>
                    </div>
                  )}
                </CardBody>
                <CardFooter className="integration-buttons">
                  {currentaccountselected.call_integrations?.includes(
                    CALL_TOKENS.s2
                  ) ? (
                    <Button onClick={() => this.openModal(CALL_TOKENS.s2)}>
                      Disable
                    </Button>
                  ) : (
                    <Button onClick={() => this.enable(CALL_TOKENS.s2)}>
                      Enable
                    </Button>
                  )}
                </CardFooter>
              </Card>
            </div>
            <div>
                <Card className='card_integration'>
                  <CardHeader>
                    <img
                      src={telinkLogo}
                      alt='telink'
                      height='20'
                      style={{ marginBottom: '7px' }}
                    />
                    <span>Telink</span>
                    <hr />
                    <CardSubtitle>
                      Enable / Disable Telink Integration on this account.
                    </CardSubtitle>
                  </CardHeader>
                  <CardBody
                    onClick={() => {
                      this.enableLynes();
                    }}
                    style={{ cursor: 'pointer' }}
                    className='d-flex justify-content-center flex-column items-aling-center'
                  >
                    <div>
                      <img src={telink} alt='telink' height={50} width={160} />
                    </div>
                  </CardBody>
                  <CardFooter className='integration-buttons'>
                  {isLynesEnabled ? (
                    <>
                      <Button
                        onClick={() => {
                          this.enableLynes();
                        }}
                        style={{ marginRight: '5px' }}
                      >
                        Info
                      </Button>
                      <Button onClick={() => this.openModal('Telink')}>
                        Disable
                      </Button>
                    </>
                  ) : (
                    <Button onClick={() => this.enableLynes()}>Enable</Button>
                  )}
                  </CardFooter>
                </Card>
              </div>
            </>
          )}
          <>
            <div>
              <Card className='card_integration'>
                <CardHeader>
                  <img
                    src={telavoxLogo}
                    alt='telavox-logo'
                    height='20'
                    style={{marginBottom: '7px'}}
                  />
                  <span>Telavox</span>
                  <hr/>
                  <CardSubtitle>
                    Enable / Disable Telavox Integration.
                  </CardSubtitle>
                </CardHeader>
                <CardBody
                  onClick={() => {
                    this.enableTelavox();
                  }}
                  style={{cursor: 'pointer'}}
                  className='d-flex justify-content-center flex-column items-aling-center'
                >
                  <div>
                    <img src={telavox} alt='telavox' height={100} width={240}/>
                  </div>
                </CardBody>
                <CardFooter className='integration-buttons'>
                  {!isTelavoxEnabled ? (
                    <Button onClick={() => this.enableTelavox()}>
                      Enable
                    </Button>
                  ) : (
                    <>
                      <Button
                        onClick={() => {
                          this.enableTelavox();
                        }}
                        style={{marginRight: '5px'}}
                      >
                        Info
                      </Button>
                      <Button onClick={() => this.openModal('Telavox')}>
                        Disable
                      </Button>
                    </>
                  )}
                </CardFooter>
              </Card>
            </div>
            <div>
              <Card className="card_integration">
                <CardHeader>
                  <img src={zohoImg} alt="loader" height="20"/>
                  <span>Zoho Integration</span>
                  <hr/>
                  <CardSubtitle>
                    Enable / Disable Zoho CRM Integration.
                  </CardSubtitle>
                </CardHeader>
                <CardBody
                  onClick={zohoIntegration ? ()=>{} : this.handleZohoClick}
                  style={{cursor: "pointer"}}
                  className='d-flex justify-content-center flex-column items-aling-center'
                >
                  <div>
                    <img
                      src={zohoImg}
                      alt="loader"
                      height={100}
                      width={200}
                    />
                  </div>
                  {zohoIntegration && (
                    <div className="finished-integration">
                      <div className="finished-integration-icon">
                        <i className="fa fa-check-circle"/>
                      </div>
                      <div
                        className="finished-integration-disconnect"
                        style={{cursor: "pointer"}}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onRemoveIntegration("zoho");
                        }}
                      >
                        <span>Disconnect</span>
                      </div>
                    </div>
                  )}
                </CardBody>
                <CardFooter>
                  {importCallsPlaceholderComponent}
                </CardFooter>
              </Card>
            </div>
            <div>
              <Card className="card_integration">
                <CardHeader>
                  <img src={dynamics365Logo} alt="loader" height="20"/>
                  <span>Microsoft Dynamics CRM Integration</span>
                  <hr/>
                  <CardSubtitle>
                    Enable / Disable Dynamics CRM Integration.
                  </CardSubtitle>
                </CardHeader>
                <CardBody
                  onClick={dynamicsIntegration ? ()=>{} : this.handleDynamicsClick}
                  style={{cursor: "pointer"}}
                  className='d-flex justify-content-center flex-column items-aling-center'
                >
                  <div>
                    <img
                      src={dynamics365}
                      alt="loader"
                      height={150}
                      width={250}
                    />
                  </div>
                  {dynamicsIntegration && (
                    <div className="finished-integration">
                      <div className="finished-integration-icon">
                        <i className="fa fa-check-circle"/>
                      </div>
                      <div
                        className="finished-integration-disconnect"
                        style={{cursor: "pointer"}}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onRemoveIntegration("dynamics365");
                        }}
                      >
                        <span>Disconnect</span>
                      </div>
                    </div>
                  )}
                </CardBody>
                <CardFooter>
                  {importCallsPlaceholderComponent}
                </CardFooter>
              </Card>
            </div>
            {/* Hidden temporarily */}
            {/*<div>*/}
            {/*  <Card className="card_integration">*/}
            {/*    <CardHeader>*/}
            {/*      <img src={crmServiceLogo} alt="loader" height="20"/>*/}
            {/*      <span>CRM Service Integration</span>*/}
            {/*      <hr/>*/}
            {/*      <CardSubtitle>*/}
            {/*        Enable / Disable CRM Service Integration.*/}
            {/*      </CardSubtitle>*/}
            {/*    </CardHeader>*/}
            {/*    <CardBody*/}
            {/*      onClick={isCRMServiceEnabled ? () => {} : this.enableCRMService}*/}
            {/*      className='d-flex justify-content-center flex-column items-aling-center'*/}
            {/*    >*/}
            {/*      <div>*/}
            {/*        <img*/}
            {/*          src={crmService}*/}
            {/*          alt="loader"*/}
            {/*          height={100}*/}
            {/*          width={250}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    </CardBody>*/}
            {/*    <CardFooter className='integration-buttons'>*/}
            {/*      {!isCRMServiceEnabled ? (*/}
            {/*        <Button onClick={() => this.enableCRMService()}>*/}
            {/*          Enable*/}
            {/*        </Button>*/}
            {/*      ) : (*/}
            {/*        <Button onClick={() => this.openModal('CRM Service')}>*/}
            {/*          Disable*/}
            {/*        </Button>*/}
            {/*      )}*/}
            {/*    </CardFooter>*/}
            {/*  </Card>*/}
            {/*</div>*/}
          </>
        </CardGroup>
        <ConfirmationModal
          isOpen={isModalOpen}
          title={title}
          handleClose={this.closeModal}
          handleDisable={this.disableAdversusOrS2}
          handleLynesDisable={this.disableLynes}
          handleTelavoxDisable={this.disableTelavox}
          handleCRMServiceDisable={this.disableCRMService}
        />
      </>
    );
  }
}

export default withRouter(IntegrationsProfile);
