export const uuidv4: any = require("uuid/v4");

export const navElements = {
  recordings: {
    name: "Recordings",
    uri: "/recordings",
    tab: "1",
  },
  snippets: {
    name: "Snippets",
    uri: "/recordings/snippets",
    tab: "2",
  },
  comments: {
    name: "Comments",
    uri: "/recordings/comments",
    tab: "3",
  },
  shareLinks: {
    name: "Shared links",
    uri: "/recordings/share-links",
    tab: "4",
  },
  sharedWithMe: {
    name: "Shared with me",
    uri: "/recordings/shared-with-me",
    tab: "5",
  },
};
