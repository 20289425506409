import { isEmpty } from "lodash";

import { DataType } from "./interface";
import { DropdownOption } from "../../../pages/Home/utils/constants";
import {
  FilterObject,
  ICallOutcome,
  ICampaign,
  IDealStatus,
  IFilterTeam,
  IFilterUser,
  IMeetingTypes,
  IRecordingType,
  TagType,
} from "../../../store/interface";
import { IGlobalFilters } from "../../Widgets/utils/constants";
import { toTitleCase } from "../../../services/utilities/newUtilservice";
import { FilterTypeKey, transformKeysToGlobalFilterKeys } from "./constants";
import { extractPlaybookIdFromWidgetComponent } from '../../Widgets/utils/helperFunction';

export const convertToDropdownOptions = (
  data: DataType,
  flag?: string
): DropdownOption[] => {
  const isSpecialFlag = flag ? isSimpleStringFilterType(flag) : false;

  return data
    ? data.map((item) => {
        const label = item?.name ? toTitleCase(item.name) : "Unknown";
        const value =
          isSpecialFlag && item?.name
            ? item.name.toLowerCase()
            : item?.id || "";
        return {
          label,
          value,
          id: item?.id || "",
        };
      })
    : [];
};

const keyMapping: { [key in keyof FilterObject]: keyof IGlobalFilters } = {
  recordingTypes: "recording_types",
  campaigns: "campaign_ids",
  filterTeams: "global_team_ids",
  filterUsers: "global_user_ids",
  meetingTypes: "global_meeting_types",
  callsOutcome: "calls_outcome",
  dealsStatus: "deals_status",
  startDate: "global_start",
  endDate: "global_end",
  globalPeriod: "global_period",
};

export function transformKeys(obj: FilterObject): IGlobalFilters {
  const transformedObject: Partial<IGlobalFilters> = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const castedKey = key as keyof FilterObject;
      const newKey = keyMapping[castedKey];

      if (newKey) {
        transformedObject[newKey] = obj[castedKey] as any;
      }
    }
  }

  return transformedObject as IGlobalFilters;
}

export function removeInvalidGlobalFilterKeys(obj: any): IGlobalFilters {
  const validKeys: (keyof IGlobalFilters)[] = [
    'recording_types',
    'campaign_ids',
    'deals_status',
    'calls_outcome',
    'specific_deal_statuses',
    'global_team_ids',
    'global_user_ids',
    'global_meeting_types',
    'interval',
    'global_start',
    'global_end',
    'global_period',
    'agent_ids',
    'limit'
  ];

  const transformedObject: Partial<IGlobalFilters> = {};

  validKeys.forEach(key => {
    if (key in obj) {
      transformedObject[key] = obj[key];
    }
  });

  return transformedObject as IGlobalFilters;
}

export function removeEmptyGlobalFilters(globalFilters: IGlobalFilters): Partial<IGlobalFilters> {
  const filteredGlobalFilters: Partial<IGlobalFilters> | any = {};

  for (const key in globalFilters) {
    if (Object.prototype.hasOwnProperty.call(globalFilters, key)) {
      const filterKey = key as keyof IGlobalFilters;
      const value = globalFilters[filterKey];

      if (value !== undefined && value !== null) {
        if (Array.isArray(value) && value.length > 0) {
          filteredGlobalFilters[filterKey] = value;
        } else if (typeof value === 'string' && value.trim() !== '') {
          filteredGlobalFilters[filterKey] = value;
        } else if (typeof value === 'number' && value !== 0) {
          filteredGlobalFilters[filterKey] = [value];
        }
      }
    }
  }

  return filteredGlobalFilters;
}

export const createFilterString = (filters: FilterObject, tags?: TagType[]) => {
  let filterString = "";
  const filterKeys = {
    campaign_ids: filters.campaigns,
    recording_types: filters.recordingTypes,
    global_meeting_types: filters.meetingTypes,
    global_team_ids: filters.filterTeams,
    global_user_ids: filters.filterUsers,
    deals_status: filters.dealsStatus,
    calls_outcome: filters.callsOutcome,
    global_start: filters.startDate,
    global_end: filters.endDate,
  };

  Object.entries(filterKeys).forEach(([key, value]) => {
    if (key === "global_start" || key === "global_end") {
      if (!isEmpty(value)) {
        filterString += `${key}=${value}&`;
      }
    } else {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          filterString += `${key}[]=${item}&`;
        });
      } else if (value !== undefined && value !== null) {
        filterString += `${key}=${value}&`;
      }
    }
  });

  if (tags && !isEmpty(tags)) {
    tags.forEach((tag) => {
      filterString += `tags[]=${tag.id}&`;
    });
  }

  if (filterString.endsWith("&")) {
    filterString = filterString.slice(0, -1);
  }

  return filterString;
};

export function findObjectsByIds(
  ids: any[],
  data: DataType,
  isRecordingOrCampaignType?: boolean
): (
  | ICampaign
  | IRecordingType
  | IMeetingTypes
  | IFilterTeam
  | IFilterUser
  | IDealStatus
  | ICallOutcome
)[] {
  const anyData = data as any[];

  const lowerCaseIds = ids.map((id) =>
    typeof id === "string" ? id.toLowerCase() : id
  );

  return anyData.filter((item) => {
    const propertyToCheck = isRecordingOrCampaignType ? "name" : "id";

    if (
      item[propertyToCheck] &&
      typeof item[propertyToCheck] === "string" &&
      !Array.isArray(item[propertyToCheck])
    ) {
      return lowerCaseIds.includes(item[propertyToCheck].toLowerCase());
    }

    return lowerCaseIds.includes(item[propertyToCheck]);
  });
}

export const filtersReverseKeyMapping: { [key in keyof IGlobalFilters]: keyof FilterObject } =
  {
    recording_types: "recordingTypes",
    campaign_ids: "campaigns",
    global_team_ids: "filterTeams",
    global_user_ids: "filterUsers",
    global_meeting_types: "meetingTypes",
    deals_status: "dealsStatus",
    calls_outcome: "callsOutcome",
    global_start: "startDate",
    global_end: "endDate",
    global_period: "globalPeriod",
  };

export function transformKeysToFilterObject(obj: IGlobalFilters): FilterObject {
  const transformedObject: Partial<FilterObject> = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const castedKey = key as keyof IGlobalFilters;
      const newKey = filtersReverseKeyMapping[castedKey];

      if (newKey) {
        transformedObject[newKey] = obj[castedKey] as any;
      }
    }
  }

  return transformedObject as FilterObject;
}

export const addAgentIdToGlobalFilters = (
  widgetComponent: string,
  globalFilters: IGlobalFilters
) => {
  let agentId = extractPlaybookIdFromWidgetComponent(widgetComponent);

  let mappedGlobalFilters = {...globalFilters};
  if (agentId) {
    mappedGlobalFilters.agent_ids = [agentId];
  } else {
    mappedGlobalFilters.agent_ids = [];
  }

  return mappedGlobalFilters;
}

export function transformKeysToGlobalFilter(obj: FilterObject): IGlobalFilters {
  const transformedObject: Partial<IGlobalFilters> = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const castedKey = key as keyof FilterObject;
      const newKey = keyMapping[castedKey];

      if (newKey) {
        transformedObject[newKey] = obj[castedKey] as any;
      }
    }
  }

  return transformedObject as IGlobalFilters;
}

export function removeEmptyGlobalFilterKeys(obj: FilterObject): FilterObject {
  let nonEmptyKeysObj: Partial<FilterObject> = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key as keyof FilterObject] as any;

      if (!isEmpty(value)) {
        nonEmptyKeysObj[key as keyof FilterObject] = value;
      }
    }
  }

  return nonEmptyKeysObj as FilterObject;
}

export const createGlobalFilterObject = (
  filterType: string,
  options: DropdownOption[] | DropdownOption
): IGlobalFilters => {
  const globalFilterKey =
    transformKeysToGlobalFilterKeys[filterType as FilterTypeKey];

  let values;
  if (Array.isArray(options)) {
    values = options.map((option) => {
      if (isSimpleStringFilterType(filterType)) {
        return option.label.toLocaleLowerCase();
      } else {
        return option.id;
      }
    });
  } else {
    if (isSimpleStringFilterType(filterType)) {
      values = [options.label.toLocaleLowerCase()];
    } else {
      values = [options.id];
    }
  }

  if (filterType === "startDate" || filterType === "endDate") {
    if (Array.isArray(options)) {
      return { [globalFilterKey]: options[0].value };
    } else {
      return { [globalFilterKey]: options.value };
    }
  }

  if (filterType === "filterTeams") {
    return {
      [globalFilterKey]: values,
      global_user_ids: [],
    };
  }

  return { [globalFilterKey]: values };
};

export const filterOutUsersByTeam = (
  filterTeamOptions: DropdownOption[],
  filterUsers: IFilterUser[]
): DropdownOption[] => {
  if (filterTeamOptions.length === 0) {
    return filterUsers.map((user) => ({
      id: user.id,
      label: user.name,
      value: user.id,
    }));
  }

  return filterUsers
    .filter((user: IFilterUser) => {
      return (
        user.teams?.some((team) =>
          filterTeamOptions.some((selectedTeam) => selectedTeam.id === team.id)
        ) ?? false
      );
    })
    .map((filteredUser) => ({
      id: filteredUser.id,
      label: filteredUser.name,
      value: filteredUser.id,
    }));
};

export const isSimpleStringFilterType = (filterType: string) => {
  return (
    filterType === "recordingTypes" ||
    filterType === "campaigns" ||
    filterType === "dealsStatus" ||
    filterType === "callsOutcome"
  );
};

export const determineGlobalFilters = (
  globalFilters: IGlobalFilters,
  widgetGlobalFilters?: IGlobalFilters,
): IGlobalFilters => {
  if (!widgetGlobalFilters || isEmpty(widgetGlobalFilters)) return globalFilters;

  const allEmptyExceptPeriod = Object.entries(widgetGlobalFilters).every(([key, value]) => {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      if (key === 'global_period' && value === '0') {
        return true;
      }

      return !value;
    }
  });

  if (allEmptyExceptPeriod) {
    return globalFilters;
  } else {
    return widgetGlobalFilters;
  }
};

export function createMeetingQueryStringFromParams(params: FilterObject) {
  const keyMap = {
    recordingTypes: "recording_types",
    campaigns: "campaign_ids",
    meetingTypes: "meeting_types",
    filterUsers: "users",
    filterTeams: "teams",
    dealsStatus: "deals_status",
    callsOutcome: "calls_outcome",
    startDate: "start_time",
    endDate: "end_time",
    globalPeriod: "global_period",
    agentIds: "agent_ids",
  };

  let queryStringParts: any = [];

  Object.entries(params).forEach(([key, value]) => {
    const mappedKey = keyMap[key as keyof FilterObject];

    if (!mappedKey) return;

    if (Array.isArray(value)) {
      value.forEach((item) => {
        queryStringParts.push(
          `${encodeURIComponent(mappedKey)}[]=${encodeURIComponent(item)}`
        );
      });
    } else if (value !== undefined && value !== null) {
      queryStringParts.push(
        `${encodeURIComponent(mappedKey)}=${encodeURIComponent(value)}`
      );
    }
  });

  return queryStringParts.join("&");
}
