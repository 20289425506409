import {
  ACCOUNT_ERROR,
  ACCOUNT_SUCCESS,
  EDIT_ACCOUNT,
  GET_ACCOUNT_DETAILS,
  GET_ACCOUNT_DETAILS_SUCCESS,
  SET_ACCOUNT_BRANDING,
  ACCOUNT_BRANDING_SUCCESS,
  ACCOUNT_BRANDING_ERROR,
  DELETE_ACCOUNT_BRANDING_IMAGE,
  GET_ALL_ACCOUNTS,
  GET_ALL_ACCOUNTS_SUCCESS,
  GET_ALL_ACCOUNTS_ERROR,
  CREATE_ACCOUNT,
} from "./actionTypes";
import { AccountState, AccountsAction } from "./interface";

const initialState: AccountState = {
  error: [],
  success: "",
  loader: false,
  accountdetails: null,
  loaderForBranding: false,
  loaderForDeleteBranding: false,
  addedImage: null,
  allAccounts: [],
  allAccountsError: "",
  allAccountsLoading: false,
};

const AccountDetails = (state = initialState, action: AccountsAction) => {
  switch (action.type) {
    case CREATE_ACCOUNT:
      state = { ...state, loader: true, error: [], success: "" };
      break;
    case EDIT_ACCOUNT:
      state = { ...state, loader: true, error: [], success: "" };
      break;
    case GET_ACCOUNT_DETAILS:
      state = { ...state, loader: true, accountdetails: [] };
      break;
    case ACCOUNT_SUCCESS:
      state = { ...state, loader: false, success: action.payload };
      break;
    case GET_ACCOUNT_DETAILS_SUCCESS:
      state = { ...state, loader: false, accountdetails: action.payload };
      break;
    case ACCOUNT_ERROR:
      state = { ...state, loader: false, error: action.payload };
      break;
    case SET_ACCOUNT_BRANDING:
      state = { ...state, loaderForBranding: true, addedImage: null, error: "" };
      break;
    case ACCOUNT_BRANDING_SUCCESS:
      state = { ...state, loaderForBranding: false, loaderForDeleteBranding: false, addedImage: action.payload };
      break;
    case ACCOUNT_BRANDING_ERROR:
      state = { ...state, loaderForBranding: false, loaderForDeleteBranding: false, error: action.payload };
      break;
    case DELETE_ACCOUNT_BRANDING_IMAGE:
      state = { ...state, loaderForDeleteBranding: true, error: "", addedImage: null, };
      break;
    case GET_ALL_ACCOUNTS:
      state = { ...state, allAccountsLoading: true, allAccountsError: "" };
      break;
    case GET_ALL_ACCOUNTS_SUCCESS:
      state = { ...state, allAccountsLoading: false, allAccounts: action.payload };
      break;
    case GET_ALL_ACCOUNTS_ERROR:
      state = { ...state, allAccountsLoading: false, allAccountsError: action.payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default AccountDetails;
