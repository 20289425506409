import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';

import { has } from 'lodash';
import {
  getAccountTokens as getAccountTokensAction,
  getAccountTokensError,
  getAccountTokensSuccess,
  deleteAccountToken as deleteAccountTokenAction,
  deleteAccountTokenError,
  deleteAccountTokenSuccess,
  createAccountToken as createAccountTokenAction,
  createAccountTokenSuccess,
  createAccountTokenError,
  editAccountToken as editAccountTokenAction,
  editAccountTokenError,
  editAccountTokenSuccess,
  enableLynesIntegration as enableLynesIntegrationAction,
  enableLynesIntegrationSuccess,
  enableLynesIntegrationError,
  disableLynesIntegration as disableLynesIntegrationAction,
  disableLynesIntegrationError,
  disableLynesIntegrationSuccess,
  enableCRMServiceIntegration as enableCRMServiceIntegrationAction,
  enableCRMServiceIntegrationError,
  enableCRMServiceIntegrationSuccess,
  disableCRMServiceIntegrationError,
  disableCRMServiceIntegrationSuccess,
} from './actions';
import {
  getAccountTokens,
  deleteAccountToken,
  createAccountToken,
  updateAccountToken,
  enableLynes,
  disableLynes,
  enableCRMService,
  disableCRMService,
} from '../../../services/settings/AccountTokens';
import { ResponseGenerator } from '../../../services/userservice';
import {
  CREATE_ACCOUNT_TOKEN,
  DELETE_ACCOUNT_TOKEN,
  EDIT_ACCOUNT_TOKEN,
  ENABLE_LYNES_INTEGRATION,
  GET_ACCOUNT_TOKENS,
  DISABLE_LYNES_INTEGRATION,
  ENABLE_CRM_SERVICE_INTEGRATION,
  DISABLE_CRM_SERVICE_INTEGRATION,
} from './actionTypes';
import { IAccountToken, IEnableCRMServiceIntegration } from './interface';

let wasDisabled = false;

function* getAccountTokensHelper({
  payload,
}: ReturnType<typeof getAccountTokensAction>) {
  const response: ResponseGenerator = yield call(
    getAccountTokens,
    payload.accountId
  );
  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getAccountTokensError(error));
    } else {
      yield put(getAccountTokensError('An error has occurred!!'));
    }
  } else {
    yield put(getAccountTokensSuccess(response as unknown as IAccountToken[]));
  }
}

function* createAccountTokenHelper({
  payload,
}: ReturnType<typeof createAccountTokenAction>) {
  const response: ResponseGenerator = yield call(
    createAccountToken,
    payload.accountId,
    payload.token
  );
  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(createAccountTokenError(error));
    } else {
      yield put(createAccountTokenError('An error has occurred!!'));
    }
  } else {
    yield put(createAccountTokenSuccess(response.data));
  }
}

function* editAccountTokenHelper({
  payload,
}: ReturnType<typeof editAccountTokenAction>) {
  const response: ResponseGenerator = yield call(
    updateAccountToken,
    payload.accountId,
    payload.token
  );

  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(editAccountTokenError(error));
    } else {
      yield put(editAccountTokenError('An error has occurred!!'));
    }
  } else {
    yield put(editAccountTokenSuccess(response.data));
  }
}

function* deleteAccountTokenHelper({
  payload,
}: ReturnType<typeof deleteAccountTokenAction>) {
  const response: ResponseGenerator = yield call(
    deleteAccountToken,
    payload.accountId,
    payload.tokenId
  );
  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(deleteAccountTokenError(error));
    } else {
      yield put(deleteAccountTokenError('An error has occurred!!'));
    }
  } else {
    yield put(deleteAccountTokenSuccess(payload.tokenId));
    const updatedAccountTokens: IAccountToken[] = yield select((state: any) => state.AccountTokens.accountTokens);
    if (updatedAccountTokens.length === 0) {
      wasDisabled = true;
    }
  }
}

function* enableLynesHelper({
  payload,
}: ReturnType<typeof enableLynesIntegrationAction>) {
  const response: ResponseGenerator = yield call(enableLynes, payload);
  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(enableLynesIntegrationError(error));
    } else {
      yield put(enableLynesIntegrationError('An error has occurred!!'));
    }
  } else {
    yield put(enableLynesIntegrationSuccess(response.data));
    if (wasDisabled) {
      yield put(getAccountTokensAction(payload));
      wasDisabled = false;
    }
  }
}

function* disableLynesHelper({
  payload,
}: ReturnType<typeof disableLynesIntegrationAction>) {
  const response: ResponseGenerator = yield call(disableLynes, payload);
  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(disableLynesIntegrationError(error));
    } else {
      yield put(disableLynesIntegrationError('An error has occurred!!'));
    }
  } else {
    yield put(disableLynesIntegrationSuccess());
    wasDisabled = true;
  }
}

function* enableCRMServiceHelper({ payload } : ReturnType<typeof enableCRMServiceIntegrationAction>) {
  const response: ResponseGenerator = yield call(enableCRMService, payload);
  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(enableCRMServiceIntegrationError(error));
    } else {
      yield put(enableCRMServiceIntegrationError('An error has occurred!!'));
    }
  } else {
    yield put(enableCRMServiceIntegrationSuccess(response.data));
  }
}

function* disableCRMServiceHelper(): Generator<any, void, ResponseGenerator> {
  const response: ResponseGenerator = yield call(disableCRMService);
  if (response.error || response.errors) {
    const error = response.error || response.errors;
    yield put(disableCRMServiceIntegrationError(error));
  } else {
    yield put(disableCRMServiceIntegrationSuccess(response.data));
    yield put({ type: 'GET_USER_PROFILE' });
  }
}

export function* watchGetAccountTokensHelper() {
  yield takeEvery(GET_ACCOUNT_TOKENS, getAccountTokensHelper);
}

export function* watchDeleteAccountTokenHelper() {
  yield takeEvery(DELETE_ACCOUNT_TOKEN, deleteAccountTokenHelper);
}

export function* watchEditAccountTokenHelper() {
  yield takeEvery(EDIT_ACCOUNT_TOKEN, editAccountTokenHelper);
}

export function* watchCreateAccountTokenHelper() {
  yield takeEvery(CREATE_ACCOUNT_TOKEN, createAccountTokenHelper);
}

export function* watchEnableLynesHelper() {
  yield takeEvery(ENABLE_LYNES_INTEGRATION, enableLynesHelper);
}

export function* watchDisableLynesHelper() {
  yield takeEvery(DISABLE_LYNES_INTEGRATION, disableLynesHelper);
}

export function* watchEnableCRMServiceHelper() {
  yield takeEvery(ENABLE_CRM_SERVICE_INTEGRATION, enableCRMServiceHelper);
}

export function* watchDisableCRMServiceHelper() {
  yield takeEvery(DISABLE_CRM_SERVICE_INTEGRATION, disableCRMServiceHelper);
}

function* AccountTokensSaga() {
  yield all([fork(watchGetAccountTokensHelper)]);
  yield all([fork(watchDeleteAccountTokenHelper)]);
  yield all([fork(watchCreateAccountTokenHelper)]);
  yield all([fork(watchEditAccountTokenHelper)]);
  yield all([fork(watchEnableLynesHelper)]);
  yield all([fork(watchDisableLynesHelper)]);
  yield all([fork(watchEnableCRMServiceHelper)]);
  yield all([fork(watchDisableCRMServiceHelper)]);
}

export default AccountTokensSaga;
