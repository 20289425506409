import React, { Component, Dispatch } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import * as dotenv from "dotenv";
import { withRouter } from "react-router-dom";
// Import Routes
import { authProtectedRoutes, publicRoutes, commonRoutes } from "./routes";
import AppRoute from "./routes/route";
import loaderImg from "./assets/images/loader.gif";
// layouts
import Cookies from "universal-cookie";
import VerticalLayout from "./components/VerticalLayout";
import ErrorBoundary from "./components/Common/ErrorBoundary";
import NonAuthLayout from "./components/NonAuthLayout";
import { logoutUser } from "./services/auth/authservice";
import _ from "lodash";
// Import scss
import "./assets/scss/theme.scss";
import { Icurrentaccountselected } from "./store/interface";
import HorizontalLayout from "./components/HorizontalLayout";
import Chat from "./components/AiChat/Chat";

interface AppProps {
  currentaccountselected: Icurrentaccountselected;
  currentuser: any;
  error: String | Object;
  layout: {
    isMobile: Boolean;
    isPreloader: Boolean;
    layoutType: String;
    layoutWidth: String;
    leftSideBarTheme: String;
    leftSideBarType: String;
    loader: Boolean;
    showRightSidebar: Boolean;
    topbarTheme: String;
  };
}

interface AppState {
  aabc: Number;
}

class App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      aabc: 1,
    };
    this.getLayout = this.getLayout.bind(this);

    // dotenv.config();
  }

  componentDidMount() {
    window.parent.postMessage('loaded', "*");
  }

  componentDidUpdate(prevProps: AppProps) {
    const { error, currentaccountselected } = this.props;
    if (!_.isEmpty(error) && error !== prevProps.error) {
      logoutUser();
    }

    if (
      !_.isEmpty(currentaccountselected) &&
      currentaccountselected !== prevProps.currentaccountselected
    ) {
      this.handleCrispScript();
    }

    setTimeout(() => {
      let windows = window as any;
      const { currentuser } = this.props;
      if (!_.isEmpty(windows.HubSpotConversations)) {
        var _hsq = (windows._hsq = windows._hsq || []);
        _hsq.push([
          "identify",
          {
            email: currentuser?.email || "",
            firstname: currentuser?.first_name || "",
            lastname: currentuser?.lase_name || "",
            companyname: currentaccountselected?.name || "",
          },
        ]);
        _hsq.push(["trackPageView"]);
        windows.HubSpotConversations.widget.refresh();
      }
    }, 2000);
  }

  handleCrispScript = (): void => {
    if (window.location.pathname === "/documents_import") return;

    const cookies = new Cookies();
    if (!cookies.get("JWT_Token")) return;

    const crispScript = document.createElement("script");
    crispScript.type = "text/javascript";
    crispScript.text = 'window.$crisp=[];window.CRISP_WEBSITE_ID="859b7b62-4753-43d8-ac13-ead3049fcfdb";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();';

    if (
      window.location.pathname.indexOf('rooms_import') < 0 &&
      window.location.pathname.indexOf('documents_import') < 0
    ) {
      document.head.appendChild(crispScript);
    }
  };

  /**
   * Returns the layout
   */
  getLayout = () => {
    // let layoutCls = VerticalLayout;
    let layoutCls = HorizontalLayout;


    switch (this.props.layout.layoutType) {
      case "horizontal":
        // layoutCls = HorizontalLayout;
        break;
      default:
        // layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  render() {
    const Layout = this.getLayout();
    return (
      <React.Fragment>
        <Router>
          <ErrorBoundary>
            {/* <React.Suspense
              fallback={
                <div className="lazyloader">
                  <div className="lazyloader-wraaper">
                    <img src={loaderImg} alt="loader" />
                  </div>
                </div>
              }
            > */}
            <Switch>
              {commonRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isCommon={true}
                  isAuthProtected={null}
                />
              ))}

              {publicRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isCommon={false}
                  isAuthProtected={false}
                />
              ))}

              {authProtectedRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isCommon={false}
                  isAuthProtected={true}
                  fromIframe={route.fromIframe}
                />
              ))}
            </Switch>
            <Chat />
            {/* </React.Suspense> */}
          </ErrorBoundary>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    layout: state.Layout,
    error: state.Login.error,
    currentaccountselected: state.Profile.currentaccountselected,
    currentuser: state.Profile.currentuser,
  };
};

export default withRouter<any, any>(connect(mapStateToProps, null)(App));
