import React, { Component } from 'react';
import {
  Button,
} from 'reactstrap';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Search } from 'history';

import { ShareLinkModal } from "../Settings/Modal/ShareLinkModal";
import VideoPlayer from "../../components/Recording/VideoPlayer";
import { deleteLocalStorage } from "../../services/utilities/utilservice";
import {
  createSnippetShareLink, createSnippetShareLinkSuccess,
  deleteRecordingShareLink,
  deleteSnippetShareLink,
  getMeetingAIAnalytics,
  getRecordingShareLinksForRecording,
  getRecordingSnippets,
  getRecordingTranscript,
  getSnippetShareLinksForSnippet,
  getStatsMeetings,
  getStatsRecordings,
  getTimeLine,
  postRecordingSnippet,
  shareRecording,
  updateRecordingShareLink,
  updateSnippet,
  updateSnippetShareLink,
  getAllTagsByAccount,
  addRecordingTag,
  deleteRecordingTag,
} from '../../store/actions';
import {
  IAnalyticsMeetingAIAnalytics,
  IAnalyticsMeetings,
  IAnalyticsMeetingTimeline,
  IAnalyticsRecordings,
  Icurrentaccountselected,
  ISnippet,
  ITranscript,
  IAssociatedUser,
  IAssociatedTeam,
  IRecordingTranscriptFilters,
  TagType,
} from '../../store/interface';
import './../../../node_modules/video-react/dist/video-react.css'
import AccessDenied from './AccessDeniedPage';

const ACCESS_DENIED = {
  title: "You don't have access to this recording",
  description: "Please ask the recording owner for a shared link",
}

interface IPermission {
  value: string,
  label: string,
  email: string,
  type: number,
  team_id: string,
  user_id: string,
  can_view: string,
  can_comment: boolean,
}

interface VideoPlayerProps {
  history: {
    push: any;
  };
  location: {
    state: {
      meeting: IAnalyticsMeetings;
    },
    search: Search;
  };
  match: any;
  users: IAssociatedUser[],
  loadingUsers: boolean;
  currentaccountselected: Icurrentaccountselected;
  currentuser: any,
  recordings: IAnalyticsRecordings,
  recordingLoader: boolean,
  meetings: IAnalyticsMeetings[];
  getStatsMeetings: (id: String, params?: String) => void;
  meetingAIAnalytics: IAnalyticsMeetingAIAnalytics[];
  getMeetingAIAnalytics: (accountId: String, sessionId: String) => void;
  timeLineData: IAnalyticsMeetingTimeline[];
  getTimeLine: (accountId: String, sessionId: String) => void;
  getStatsRecordings: (id: String, params?: String) => void;
  shareRecording: (data: Object, onSuccess: any) => void;
  loadingAnalytics: boolean;
  recordingShareLinks: any[];
  shareRecordingUrl: string;
  shareRecordingError: String | Object;
  getRecordingShareLinksForRecording: (recordingSessionId: string) => void;
  updateRecordingShareLink: (id: string, data: Object, onSuccess: any) => void;
  deleteRecordingShareLink: (shareLinkId: string, onSuccess: any) => void;
  snippets: ISnippet[];
  getRecordingSnippets: (recordingSessionId: string) => void;
  postRecordingSnippet: (sessionId: string, data: Object, onSuccess: any) => void;
  updateSnippet: (sessionId: string, data: Object, onSuccess: any) => void;
  snippetPostSuccess: Object | string;
  snippetShareLinks: any[];
  snippetShareLinkUrl: string;
  snippetShareLinkError: String | Object;
  getSnippetShareLinksForSnippet: (id: string) => void;
  createSnippetShareLink: (data: Object, onSuccess: any) => void;
  createSnippetShareLinkSuccess: (mes: String) => void;
  updateSnippetShareLink: (id: number, data: Object, onSuccess: any) => void;
  deleteSnippetShareLink: (shareLinkId: string, onSuccess: any) => void;
  recordingTranscript: ITranscript | null;
  getRecordingTranscript: (sessionId: string, filters?: IRecordingTranscriptFilters) => void;
  teams: IAssociatedTeam[];
  permissions?: any[];
  allTagsFromAccount: TagType[];
  getAllTagsByAccount: (accountId: String) => void;
  addRecordingTag: (name: string, sessionId: String, accountId: String) => void;
  deleteRecordingTag: (id: string, sessionId: String, accountId: String) => void;
}

interface VideoPlayerState {
  showShareLinkModal: boolean;
  shareLinkType: 'recording' | 'snippet';
  snippetUuid: string,
  shareToUser: IPermission[];
  generalAccess: string;
  showClipboardMessage: boolean,
  isEdit: boolean,
  recordingLoaded: boolean;
}

class VideoPlayerPage extends Component<
  VideoPlayerProps,
  VideoPlayerState
> {
  constructor(props: VideoPlayerProps) {
    super(props);
    this.state = {
      showShareLinkModal: false,
      shareLinkType: "recording",
      snippetUuid: "",
      shareToUser: [],
      generalAccess: 'disabled',
      showClipboardMessage: false,
      isEdit: false,
      recordingLoaded: false,
    };

    deleteLocalStorage('share-link-token', null);
  }

  componentDidMount() {
    const {
      currentaccountselected,
      meetings,
    } = this.props;
    if (!_.isEmpty(currentaccountselected.id) && !meetings) {
      this.props.getAllTagsByAccount(currentaccountselected.id);
    }
  }

  componentDidUpdate(prevProps: VideoPlayerProps, prevState: VideoPlayerState) {
    const {
      match,
      currentaccountselected,
      meetings,
      recordings,
      recordingShareLinks,
      getStatsRecordings: getStatsRecordingsAction,
      getRecordingShareLinksForRecording
    } = this.props;

    const sessionId = match.params.sessionId;

    if (
      !_.isEmpty(currentaccountselected) &&
      !meetings
    ) {
      this.props.getStatsMeetings(
        currentaccountselected.id,
        'session_id=' + sessionId,
      );
      this.props.getAllTagsByAccount(currentaccountselected.id);
    }

    if (
      !_.isEmpty(currentaccountselected) &&
      !recordings
        && !this.state.recordingLoaded
    ) {
      this.setState({recordingLoaded : true});
      getStatsRecordingsAction(sessionId);
      getRecordingShareLinksForRecording(sessionId);
    }

    if (!_.isEmpty(recordingShareLinks) && prevProps.recordingShareLinks === null) {
      this.setState({ isEdit: true });
      let recordingSharePermissions: any[] = []
      recordingShareLinks[0].permissions.forEach((permission: any) => {
        let type = 0;
          if (permission.type === 'team') {
            type = 1;
          } else if (permission.type === 'user') {
            type = 2;
          } else if (permission.type === 'account') {
            type = 3;
          }

        if (type !== 0) {
          let label = permission.name;

          if (permission.type === 'account') {
            label = 'Account members'
          }

          recordingSharePermissions.push(
            {
              can_comment: permission.can_comment,
              can_view: permission.can_view,
              label,
              email: permission.email,
              type,
              value: permission.user_id ? permission.user_id : (permission.team_id ? permission.team_id : null),
              team_id: permission.team_id,
              user_id: permission.user_id,
            }
          )
        }else {
          this.setState({ generalAccess: 'anyone' });
        }
      });
  
      this.setState({
        shareToUser: recordingSharePermissions
      });
    }
  }

  shouldComponentUpdate(nextProps: VideoPlayerProps, nextState: VideoPlayerState) {
    if (
      this.props.currentaccountselected !== nextProps.currentaccountselected ||
      this.props.recordings !== nextProps.recordings ||
      this.props.recordingLoader !== nextProps.recordingLoader ||
      this.props.meetings !== nextProps.meetings ||
      this.props.users !== nextProps.users ||
      this.props.teams !== nextProps.teams ||
      this.props.loadingUsers !== nextProps.loadingUsers ||
      this.props.timeLineData !== nextProps.timeLineData ||
      this.props.meetingAIAnalytics !== nextProps.meetingAIAnalytics ||
      this.state.showShareLinkModal !== nextState.showShareLinkModal ||
      this.props.shareRecordingUrl !== nextProps.shareRecordingUrl ||
      this.props.loadingAnalytics !== nextProps.loadingAnalytics ||
      this.props.recordingShareLinks !== nextProps.recordingShareLinks ||
      this.props.shareRecordingError !== nextProps.shareRecordingError ||
      this.props.snippets !== nextProps.snippets ||
      this.props.snippetPostSuccess !== nextProps.snippetPostSuccess ||
      this.props.snippetShareLinks !== nextProps.snippetShareLinks ||
      this.props.snippetShareLinkUrl !== nextProps.snippetShareLinkUrl ||
      this.props.snippetShareLinkError !== nextProps.snippetShareLinkError ||
      this.props.recordingTranscript !== nextProps.recordingTranscript ||
      this.state.shareToUser !== nextState.shareToUser ||
      this.state.generalAccess !== nextState.generalAccess ||
      this.state.showClipboardMessage !== nextState.showClipboardMessage
    ) {
      return true;
    }

    return false;
  }

  handleRedirect = () => {
    this.props.history.push({
      pathname: `/insights/meetings`
    });
  };

  openShareLinkModal() {
    this.setState(
      {
        shareLinkType: 'recording',
        showShareLinkModal: true,
      }
    )
  }

  closeShareLinkModal() {
    this.handleShareRecording();
    const {
      createSnippetShareLinkSuccess: createSnippetShareLinkSuccessAction,
    } = this.props;

    createSnippetShareLinkSuccessAction("");

    this.setState(
      {
        showShareLinkModal: false,
      }
    )
  }

  handleSnippetShare = (id: string) => {
    const {
      getSnippetShareLinksForSnippet
    } = this.props;

    getSnippetShareLinksForSnippet(id);
  }

  sortByKey = (array: any, key: string) => {
    return array.sort(function (a: any, b: any) {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  checkExists = (value: string) => {
    return this.state.shareToUser.map((user) => user.value).includes(value);
  }

  onSelectSharedTarget = (target: any) => {
    const newTarget = {
      ...target,
      can_comment: true // set can_comment to true by default
    };

    if (!this.checkExists(newTarget.value)) {
      this.setState({ shareToUser: this.sortByKey([...this.state.shareToUser, newTarget], 'type') });
    }
    setTimeout(() => {
      this.handleShareRecording()
    }, 1);
  }

  canViewHandler = (label: string, permission: any) => {
    this.setState({
      shareToUser: this.state.shareToUser.map((user) => {
        if (user.label === label) {
          user.can_view = permission.value
        }
        return user;
      })
    });
    setTimeout(() => {
      this.handleShareRecording()
    }, 1);
  }

  canCommentHandler = (label: string) => {
    this.setState({
      shareToUser: this.state.shareToUser.map((user) => {
        if (user.label === label) {
          user.can_comment = !user.can_comment;
        }

        return user;
      })
    });
    setTimeout(() => {
      this.handleShareRecording()
    }, 1);
  }

  removeShareUser = (label: string) => {
    this.setState({ shareToUser: this.state.shareToUser.filter((user) => user.label !== label) });
    setTimeout(() => {
      this.handleShareRecording()
    }, 1);
  }

  onHandleGeneralAccess = (option: string) => {
    this.setState({ generalAccess: option });
    setTimeout(() => {
      this.handleShareRecording()
    }, 1);
  }

  handleGetShareLinkPermissions = (): any => {
    const userPermissions = this.state.shareToUser.map((user) => {
      let type = 'account';
      if (user.type === 1) {
        type = 'team';
      } else if (user.type === 2) {
        type = 'user';
      }
      return ({
        type: type,
        user_id: user.user_id ? user.user_id : null,
        team_id: user.team_id ? user.team_id : null,
        can_view: user.can_view,
        can_comment: user.can_comment,
      })
    })

    if (this.state.generalAccess === "anyone") {
      const generalPermissions = {
        type: 'other',
        can_view: 'none',
        can_comment: false,
        user_id: null,
        team_id: null,
      }

      return [
        ...userPermissions,
        generalPermissions
      ];
    }

    return userPermissions;
  }

  handleSuccess = () => {
    const { getRecordingShareLinksForRecording } = this.props;

    getRecordingShareLinksForRecording(this.props.match.params.sessionId);
    if (!this.state.showClipboardMessage) {
      this.setState({
        showClipboardMessage: true,
        isEdit: true,

      })
    }

    setTimeout(() => {
      this.setState({
        showClipboardMessage: false
      });
    }, 3000);
  };

  handleShareRecording = () => {
    const {
      shareRecording: shareRecordingAction,
      getRecordingShareLinksForRecording: getRecordingShareLinksForRecordingAction,
      updateRecordingShareLink: updateRecordingShareLinkAction
    } = this.props;

    const {
      recordingShareLinks
    } = this.props

    const payload = {
      recording_id: this.props.match.params.sessionId,
      name: this.props.meetings[0].name,
      emails: [],
      permission: this.handleGetShareLinkPermissions(),
      include_comments: true,
      include_enriched_timeline: true,
      include_ai_insights: true,
    };

    if (recordingShareLinks && recordingShareLinks[0]?.id) {
      updateRecordingShareLinkAction(
        recordingShareLinks[0]?.id,
        payload,
        (link: string) => {
          navigator.clipboard.writeText(link);
          this.handleSuccess();
        }
      )
    } else {
      shareRecordingAction(
        payload,
        (link: string) => {
          navigator.clipboard.writeText(link);
          this.handleSuccess();
        }
      );
    }
  }

  onAddTag = (name: string) => {
    const { match, currentaccountselected, addRecordingTag } = this.props;
    const sessionId = match.params.sessionId;
    addRecordingTag(name, sessionId, currentaccountselected.id);
  };

  deleteTag = (id: string) => {
    const { match, currentaccountselected, deleteRecordingTag } = this.props;
    const sessionId = match.params.sessionId;

    deleteRecordingTag(id, sessionId, currentaccountselected.id);
  }

  render() {
    const {
      showShareLinkModal,
    } = this.state;
    const {
      location,
      currentaccountselected,
      currentuser,
      meetings,
      recordings,
      recordingLoader,
      meetingAIAnalytics,
      timeLineData,
      snippets,
      recordingShareLinks,
      snippetShareLinks,
      snippetPostSuccess,
      recordingTranscript,
      allTagsFromAccount,
      getMeetingAIAnalytics: getMeetingAiAnalyticsAction,
      getTimeLine: getTimeLineAction,
      getRecordingSnippets: getRecordingSnippetsAction,
      postRecordingSnippet: postRecordingSnippetAction,
      getRecordingTranscript: getRecordingTranscriptAction,
    } = this.props;

    const params = new URLSearchParams(location.search);
    const start = params.get('start');

    const meeting = _.isEmpty(meetings)
      ? false
      : _.find(meetings, (item: IAnalyticsMeetings) => item.session_id == this.props.match.params.sessionId);

    const permissions = _.isEmpty(snippetShareLinks)
      ? []
      : snippetShareLinks[0].permissions
    ;

    const snippetShareLinkId = _.isEmpty(snippetShareLinks)
    ? ''
    : snippetShareLinks[0].id

    const userOptions = this.props.users?.map((user) => ({ value: user.id, label: user.first_name + ' ' + user.last_name, email: user.email, type: 2, user_id: user.id, can_comment: false, can_view: 'all' }));
    const teamOptions = this.props.teams?.map((team) => ({ value: team.id, label: team.name, type: 1, team_id: team.id, can_comment: false, can_view: 'all' }));

    const groupedOptions = [
      {
        label: "Account members",
        options: [{ value: "Account members", label: "Account members", type: 0, can_comment: false, can_view: 'all' }]
      },
      {
        label: "Team",
        options: teamOptions
      },
      {
        label: "Users",
        options: userOptions
      }
    ];

    return (
      <>
        {meeting &&
          <>
            <VideoPlayer
              sessionId={this.props.match.params.sessionId}
              recordingSessionId={this.props.match.params.sessionId}
              accountId={currentaccountselected.id}
              currentuser={currentuser}
              recording={recordings}
              recordingLoader={recordingLoader}
              meeting={meeting}
              showComments={true}
              showSnippets={true}
              showAddSnippet={true}
              timeLineData={timeLineData}
              getTimeLine={getTimeLineAction}
              meetingAIAnalytics={meetingAIAnalytics}
              getMeetingAIAnalytics={getMeetingAiAnalyticsAction}
              getRecordingSnippets={getRecordingSnippetsAction}
              postRecordingSnippet={postRecordingSnippetAction}
              snippetPostSuccess={snippetPostSuccess}
              handleRedirect={this.handleRedirect}
              handleSnippetShare={this.handleSnippetShare}
              snippets={snippets}
              showTranscription={currentaccountselected.transcription_enabled}
              recordingTranscript={currentaccountselected.transcription_enabled ? recordingTranscript : null}
              getRecordingTranscript={currentaccountselected.transcription_enabled ? getRecordingTranscriptAction : undefined}
              isSuperAdmin={currentuser.isSuperAdmin}
              start={start}
              users={this.props.users}
              teams={this.props.teams}
              createShareLink={this.props.createSnippetShareLink}
              updateShareLink={this.props.updateSnippetShareLink}
              history={this.props.history}
              permissions={permissions}
              canComment={true}
              snippetShareLinkId={snippetShareLinkId}
              forceShowSnippetDiv={!showShareLinkModal}
              updateSnippetAction={this.props.updateSnippet}
              canExportCSV={true}
              accountTags={allTagsFromAccount}
              onAddTag={this.onAddTag}
              deleteTag={this.deleteTag}
              entityTags={recordings?.tags}
            />
            <Button
              className="share-button"
              color="blue"
              type="button"
              onClick={() => this.openShareLinkModal()}
            >
              Share recording
            </Button>
            {showShareLinkModal &&
            <ShareLinkModal
              onSelectSharedTarget={this.onSelectSharedTarget}
              groupedOptions={groupedOptions}
              shareToUser={this.state.shareToUser}
              canViewHandler={this.canViewHandler}
              canCommentHandler={this.canCommentHandler}
              removeShareUser={this.removeShareUser}
              generalAccess={this.state.generalAccess}
              onHandleGeneralAccess={this.onHandleGeneralAccess}
              onClose={() => this.closeShareLinkModal()}
              handleShareRecording={this.handleShareRecording}
              showClipboardMessage={this.state.showClipboardMessage}
              isEdit={this.state.isEdit}
            />
            }
          </>
        }
        {meetings && !meeting &&
        <AccessDenied title={ACCESS_DENIED.title} description={ACCESS_DENIED.description}/>
        }
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  const {
    recordings,
    recordingLoader,
    meetings,
    timeLineData,
    meetingAIAnalytics,
    recordingShareLinks,
    loading: loadingAnalytics,
    shareRecordingError,
    shareRecordingUrl,
    snippets,
    snippetPostSuccess,
    snippetShareLinks,
    snippetShareLinkUrl,
    snippetShareLinkError,
    recordingTranscript,
    allTagsFromAccount,
  } = state.SalesAnalytics;
  const {
    currentaccountselected,
    currentuser,
  } = state.Profile;

  const { loading: loadingUsers } = state.getUsersList;
  const { users } = state.getAssociatedUsers;
  const { teams } = state.getAssociatedTeams;

  return {
    currentaccountselected,
    currentuser,
    recordings,
    recordingLoader,
    meetings,
    users,
    teams,
    loadingUsers,
    timeLineData,
    meetingAIAnalytics,
    loadingAnalytics,
    recordingShareLinks,
    shareRecordingError,
    shareRecordingUrl,
    snippets,
    snippetPostSuccess,
    snippetShareLinks,
    snippetShareLinkUrl,
    snippetShareLinkError,
    recordingTranscript,
    allTagsFromAccount,
  };
};

export default connect(mapStateToProps, {
  getStatsMeetings,
  getStatsRecordings,
  getTimeLine,
  getMeetingAIAnalytics,
  getRecordingShareLinksForRecording,
  shareRecording,
  getRecordingSnippets,
  postRecordingSnippet,
  deleteRecordingShareLink,
  updateRecordingShareLink,
  getSnippetShareLinksForSnippet,
  createSnippetShareLink,
  createSnippetShareLinkSuccess,
  updateSnippetShareLink,
  deleteSnippetShareLink,
  getRecordingTranscript,
  updateSnippet,
  getAllTagsByAccount,
  addRecordingTag,
  deleteRecordingTag,
})(VideoPlayerPage);
