import axios, { AxiosResponse } from "axios";

import { urls } from "../../utilities/urls";
import { endpoints } from "../../utilities/endpoints";
import { commonheaders } from "../../utilities/apiConfig";
import { IChatInformation, IMessage } from "../../store/interface";

export async function createChatSession(
  chatTitle: string
): Promise<AxiosResponse<IChatInformation>> {
  const response = await axios
    .post(
      `${urls.baseApiURL}${endpoints.aiChatSession}/prompt`,
      {
        prompt: chatTitle,
      },
      {
        headers: commonheaders,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response.data;
}

export async function editChat(chatId: string, chatTitle: string) {
  try {
    const response = await axios.put(
      `${urls.baseApiURL}${endpoints.aiChatSession}/${chatId}`,
      {
        chat_name: chatTitle,
      },
      {
        headers: commonheaders,
      }
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else if (error.request) {
      throw error.request;
    } else {
      throw error.message;
    }
  }
}

export async function deleteChat(chatId: string) {
  try {
    const response = await axios.delete(
      `${urls.baseApiURL}${endpoints.aiChatSession}/${chatId}`,
      {
        headers: commonheaders,
      }
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else if (error.request) {
      throw error.request;
    } else {
      throw error.message;
    }
  }
}

export async function getChatTopics(): Promise<
  AxiosResponse<IChatInformation>
> {
  try {
    const response = await axios.get(
      `${urls.baseApiURL}${endpoints.aiChatSession}`,
      {
        headers: commonheaders,
      }
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else if (error.request) {
      throw error.request;
    } else {
      throw error.message;
    }
  }
}

export async function getChatMessages(
  chatId: string
): Promise<AxiosResponse<IMessage[]>> {
  const response = await axios
    .get(
      `${urls.baseApiURL}${endpoints.aiChatSession}/${chatId}`,
      {
        headers: commonheaders,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response.data;
}

export async function sendMessage(chatId: string, message: string) {
  try {
    const response = await axios.post(
      `${urls.baseApiURL}${endpoints.aiChatSession}/${endpoints.prompt}/${chatId}`,
      {
        prompt: message,
      },
      {
        headers: commonheaders,
      }
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else if (error.request) {
      throw error.request;
    } else {
      throw error.message;
    }
  }
}
