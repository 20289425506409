import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  EDIT_ACCOUNT,
  GET_ACCOUNT_DETAILS,
  SET_ACCOUNT_BRANDING,
  DELETE_ACCOUNT_BRANDING_IMAGE,
  GET_ALL_ACCOUNTS,
  CREATE_ACCOUNT,
} from "./actionTypes";
import {
  accountSuccess,
  getAccountDetailsSuccess,
  accountError,
  accountBrandingError,
  accountBrandingSuccess,
  getAllAccountsSuccess,
  getAllAccountsError,
} from "./actions";

import {
  getAccountDetails,
  editAccountDetails,
  setAccountBranding,
  deleteAccountBrandingImage,
  getAllAccountsDetails,
  createAccountDetails,
  ResponseGenerator,
} from "../../../services/userservice";
import _ from "lodash";
import { setLocalStorage } from "../../../services/utilities/utilservice";

function* createAccount({ payload: { account } }: any) {
  const response: ResponseGenerator = yield call(createAccountDetails, account);
  if (_.has(response, "data")) {
    yield put(accountSuccess("Account has been created successfully"));
    account.onSuccess(response.data);
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(accountError(error));
    } else {
      yield put(accountError("An Error has occurred"));
    }
  }
}

function* editAccount({ payload: { account, id } }: any) {
  const response: ResponseGenerator = yield call(editAccountDetails, account, id);
  if (_.has(response, "data")) {
    yield put(accountSuccess("Details has been edited successfully"));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(accountError(error));
    } else {
      yield put(accountError("An Error has occured"));
    }
  }
}

function* getAccountsHelper({ payload: { id } }: any) {
  const response: ResponseGenerator = yield call(getAccountDetails, id);
  if (_.has(response, "data")) {
    setLocalStorage('is_beta', response.data[0].is_beta ? true :  false)
    yield put(getAccountDetailsSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(accountError(error));
    } else {
      yield put(accountError("An Error has occured"));
    }
  }
}

function* setAccountBrandingHelper({ payload: { accountId, data, isMetting } }: any) {
  const response: ResponseGenerator = yield call(setAccountBranding, accountId, data, isMetting);
  if (_.has(response, "data")) {
    yield put(accountBrandingSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(accountBrandingError(error));
    } else {
      yield put(accountBrandingError("An Error has occured"));
    }
  }
}

function* deleteAccountBrandingImageHelper({ payload: { brandingId, data, isMetting } }: any) {
  const response: ResponseGenerator = yield call(deleteAccountBrandingImage, brandingId, data, isMetting);
  if (_.has(response, "data")) {
    yield put(accountBrandingSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(accountBrandingError(error));
    } else {
      yield put(accountBrandingError("An Error has occured"));
    }
  }
}

function* getAllAccountsHelper() {
  const response: ResponseGenerator = yield call(getAllAccountsDetails);
  if (_.has(response, "data")) {
    yield put(getAllAccountsSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(getAllAccountsError(error));
    } else {
      yield put(getAllAccountsError("An Error has occurred"));
    }
  }
}

export function* watchAccount() {
  yield takeEvery(EDIT_ACCOUNT, editAccount);
}

export function* watchCreateAccount() {
  yield takeEvery(CREATE_ACCOUNT, createAccount);
}

export function* watchGetAccountDetails() {
  yield takeEvery(GET_ACCOUNT_DETAILS, getAccountsHelper);
}

export function* watchSetAccountBranding() {
  yield takeEvery(SET_ACCOUNT_BRANDING, setAccountBrandingHelper);
}

export function* watchDeleteAccountBrandingImage() {
  yield takeEvery(DELETE_ACCOUNT_BRANDING_IMAGE, deleteAccountBrandingImageHelper);
}

export function* watchAllAccounts() {
  yield takeEvery(GET_ALL_ACCOUNTS, getAllAccountsHelper);
}

function* AccountDetailsSaga() {
  yield all([
    fork(watchCreateAccount),
    fork(watchAccount),
    fork(watchGetAccountDetails),
    fork(watchSetAccountBranding),
    fork(watchDeleteAccountBrandingImage),
    fork(watchAllAccounts),
  ]);
}

export default AccountDetailsSaga;
