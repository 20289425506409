import { ITranscript } from '../interface';
import {
  CREATE_AI_AGENT,
  CREATE_AI_AGENT_SUCCESS,
  GET_AI_AGENTS,
  GET_AI_AGENTS_ERROR,
  GET_AI_AGENTS_SUCCESS,
  CREATE_AI_AGENT_ERROR,
  DELETE_AI_AGENT,
  DELETE_AI_AGENT_SUCCESS,
  DELETE_AI_AGENT_ERROR,
  UPDATE_AI_AGENT,
  UPDATE_AI_AGENT_SUCCESS,
  UPDATE_AI_AGENT_ERROR,
  GET_AI_AGENT_REQUESTS,
  GET_AI_AGENT_REQUESTS_SUCCESS,
  GET_AI_AGENT_REQUESTS_ERROR,
  SET_CURRENT_AI_AGENT,
  SEND_AI_AGENT_REQUEST,
  SEND_AI_AGENT_REQUEST_SUCCESS,
  SEND_AI_AGENT_REQUEST_ERROR,
  TRY_AI_AGENT_REQUEST,
  TRY_AI_AGENT_REQUEST_SUCCESS,
  TRY_AI_AGENT_REQUEST_ERROR,
  GET_AI_AGENT_REQUEST,
  GET_AI_AGENT_REQUEST_SUCCESS,
  GET_AI_AGENT_REQUEST_ERROR,
  GET_AI_AGENT,
  GET_AI_AGENT_SUCCESS,
  GET_AI_AGENT_ERROR,
  GET_AI_AGENT_TYPES,
  GET_AI_AGENT_TYPES_SUCCESS,
  GET_AI_AGENT_TYPES_ERROR,
  SET_TRANSCRIPT,
  EMPTY_TRANSCRIPT,
  EMPTY_AGENT,
} from './actionTypes';
import {
  ICreateAIAgent,
  IAIAgent,
  IAIAgentFromBackend,
  IAIAgentRequest,
  IAITypeFromBackend,
  IAIAgentRequestFromBackend,
} from './interface';
import { IPlaybookCustomBlock, IPlaybookCustomBlockData } from '../../pages/AIAgent/utils/interface';

export const getAIAgents = (accountId: String) => {
  return {
    type: GET_AI_AGENTS,
    payload: { accountId },
  };
};

export const getAIAgentsSuccess = (agent: IAIAgentFromBackend[]) => {
  return {
    type: GET_AI_AGENTS_SUCCESS,
    payload: agent,
  };
};

export const getAIAgentsError = (error: string | Object) => {
  return {
    type: GET_AI_AGENTS_ERROR,
    payload: error,
  };
};

export const createAIAgent = (
  accountId: String,
  agent: ICreateAIAgent,
  sessionId?: string,
  onSuccess?: (
    newAgent: IAIAgentFromBackend,
    sessionID: string,
    accountId: string,
  ) => void,
) => {
  return {
    type: CREATE_AI_AGENT,
    payload: { accountId, agent, sessionId, onSuccess },
  };
};

export const createAIAgentSuccess = (agent: IAIAgent) => {
  return {
    type: CREATE_AI_AGENT_SUCCESS,
    payload: agent,
  };
};

export const createAIAgentError = (error: string | Object) => {
  return {
    type: CREATE_AI_AGENT_ERROR,
    payload: error,
  };
};

export const deleteAIAgent = (accountId: String, agentId: number) => {
  return {
    type: DELETE_AI_AGENT,
    payload: { accountId, agentId },
  };
};

export const deleteAIAgentSuccess = () => {
  return {
    type: DELETE_AI_AGENT_SUCCESS,
    payload: null,
  };
};

export const deleteAIAgentError = (error: string | Object) => {
  return {
    type: DELETE_AI_AGENT_ERROR,
    payload: error,
  };
};

export const updateAIAgent = (
  accountId: String,
  agent: ICreateAIAgent,
  sessionId?: string,
  onSuccess?: (
    updatedAgent: IAIAgentFromBackend,
    sessionID: string,
    accountId: string,
  ) => void,
) => {
  return {
    type: UPDATE_AI_AGENT,
    payload: { accountId, agent, sessionId, onSuccess },
  };
};

export const updateAIAgentSuccess = (agent: IAIAgentFromBackend) => {
  return {
    type: UPDATE_AI_AGENT_SUCCESS,
    payload: agent,
  };
};

export const updateAIAgentError = (error: string | Object) => {
  return {
    type: UPDATE_AI_AGENT_ERROR,
    payload: error,
  };
};

export const getAIAgent = (accountId: String, agentID: number) => {
  return {
    type: GET_AI_AGENT,
    payload: { accountId, agentID },
  };
};

export const getAIAgentSuccess = (agent: IAIAgentFromBackend) => {
  return {
    type: GET_AI_AGENT_SUCCESS,
    payload: agent,
  };
};

export const getAIAgentError = (error: string | Object) => {
  return {
    type: GET_AI_AGENT_ERROR,
    payload: error,
  };
};

export const getAIAgentRequests = (accountId: String, agentId: number) => {
  return {
    type: GET_AI_AGENT_REQUESTS,
    payload: { accountId, agentId },
  };
};

export const getAIAgentRequestsSuccess = (
  aiAgentRequests: IAIAgentRequestFromBackend[]
) => {
  return {
    type: GET_AI_AGENT_REQUESTS_SUCCESS,
    payload: aiAgentRequests,
  };
};

export const getAIAgentRequestsError = (error: string | Object) => {
  return {
    type: GET_AI_AGENT_REQUESTS_ERROR,
    payload: error,
  };
};

export const setCurrentAIAgent = (
  aiAgent: IAIAgentFromBackend
) => {
  return {
    type: SET_CURRENT_AI_AGENT,
    payload: aiAgent,
  };
};

export const sendAIAgentRequest = (
  accountID: String,
  agentID: number,
  sessionID: string
) => {
  return {
    type: SEND_AI_AGENT_REQUEST,
    payload: { accountID, agentID, sessionID },
  };
};

export const sendAIAgentRequestSuccess = (
  aiAgentRequest: IAIAgentRequestFromBackend
) => {
  return {
    type: SEND_AI_AGENT_REQUEST_SUCCESS,
    payload: aiAgentRequest,
  };
};

export const sendAIAgentRequestError = (error: string | Object) => {
  return {
    type: SEND_AI_AGENT_REQUEST_ERROR,
    payload: error,
  };
};

export const tryAIAgentRequest = (
  accountID: string,
  agent: ICreateAIAgent,
  sessionID: string
) => {
  return {
    type: TRY_AI_AGENT_REQUEST,
    payload: { accountID, agent, sessionID },
  };
};

export const tryAIAgentRequestSuccess = (
  aiAgentRequest: IAIAgentRequestFromBackend
) => {
  return {
    type: TRY_AI_AGENT_REQUEST_SUCCESS,
    payload: aiAgentRequest,
  };
};

export const tryAIAgentRequestError = (error: string | Object) => {
  return {
    type: TRY_AI_AGENT_REQUEST_ERROR,
    payload: error,
  };
};

export const getAIAgentRequest = (
  accountId: String,
  agentId: number,
  requestId: number
) => {
  return {
    type: GET_AI_AGENT_REQUEST,
    payload: { accountId, agentId, requestId },
  };
};

export const getAIAgentRequestSuccess = (
  aiAgentRequest: IAIAgentRequestFromBackend
) => {
  return {
    type: GET_AI_AGENT_REQUEST_SUCCESS,
    payload: aiAgentRequest,
  };
};

export const getAIAgentRequestError = (error: string | Object) => {
  return {
    type: GET_AI_AGENT_REQUEST_ERROR,
    payload: error,
  };
};

export const getAIAgentTypes = (accountId: String) => ({
  type: GET_AI_AGENT_TYPES,
  payload: accountId,
});

export const getAIAgentTypesSuccess = (aiAgentTypes: IAITypeFromBackend[]) => ({
  type: GET_AI_AGENT_TYPES_SUCCESS,
  payload: aiAgentTypes,
});

export const getAIAgentTypesError = (error: string | Object) => ({
  type: GET_AI_AGENT_TYPES_ERROR,
  payload: error,
});

export const createAgentFromAPIResponse = (agent: IAIAgentFromBackend): IAIAgent => {
  return {
    id: agent.id,
    name: agent.name,
    type: agent.type,
    status: agent.status,
    customPrompt: agent.custom_prompt,
    typeSettings: agent.type_settings,
    globalFilters: agent.global_filters,
    createdAt: agent.created_at,
  };
}

export const createAgentRequestFromAPIResponse = (
  response: IAIAgentRequestFromBackend
): IAIAgentRequest => {
  return {
    id: response.id,
    agentId: response.agent_id,
    agentCustomPrompt: response.agent_custom_prompt,
    processResponse: response.process_response,
    agentType: response.agent_type,
    agentLanguage: response.agent_language,
    agentTypeSettings: response.agent_type_settings,
    request: response.request,
    response: response.response,
    status: response.status,
    testRun: response.test_run,
    sessionId: response.session_id,
    createdAt: response.created_at,
    finishedAt: response.finished_at || '',
  };
}

export const createPlaybookBlocksFromAPIResponse = (
  response: IPlaybookCustomBlockData[]
): IPlaybookCustomBlock[] => {
  return response.map((block) => {
    const { parent_id, children, ...rest } = block;
    return {
      ...rest,
      parentId: parent_id,
      children: children ? createPlaybookBlocksFromAPIResponse(children) : undefined,
    };
  });
}

export const setTranscript = (transcript: ITranscript[]) => ({
  type: SET_TRANSCRIPT,
  payload: transcript,
});

export const emptyTranscript = () => ({
  type: EMPTY_TRANSCRIPT,
});

export const emptyAgent = () => ({
  type: EMPTY_AGENT,
});
