import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

import GptPromptTab from '../../pages/GptPrompt/GPTPromptTab';

interface GPTPromptTabComponentProps {
  promptIds: string[];
}

interface GPTPromptTabComponentState {
  activeTab: string;
  isTableFormats: { [promptId: string]: boolean };
  dataLengths: { [promptId: string]: number };
}

class GPTPromptTabComponent extends Component<
  GPTPromptTabComponentProps,
  GPTPromptTabComponentState
> {
  state = {
    activeTab: '1',
    dataLengths: {} as { [promptId: string]: number },
    isTableFormats: {} as { [promptId: string]: boolean },
  };

  toggle = (tab: string) => {
    const { activeTab } = this.state;

    if (activeTab !== tab) this.setState({ activeTab: tab });
  };

  handleDataLengthChange = (promptId: string, dataLength: number) => {
    this.setState((prevState) => ({
      dataLengths: {
        ...prevState.dataLengths,
        [promptId]: dataLength,
      },
    }));
  };

  handleOnTableFormatChange = (promptId: string, isTableFormat: boolean) => {
    this.setState((prevState) => ({
      isTableFormats: {
        ...prevState.isTableFormats,
        [promptId]: isTableFormat,
      },
    }));
  };

  render() {
    const { promptIds } = this.props;
    const { activeTab, dataLengths, isTableFormats } = this.state;

    return (
      <div className="gpt-prompt-tab">
        <Nav tabs className="gpt-prompt-nav-tab">
          {promptIds?.map((promptId, index) => {
            const tabId = `${index + 1}`;
            return (
              <NavItem key={promptId}>
                <NavLink
                  className={classnames({ active: activeTab === tabId })}
                  onClick={() => {
                    this.toggle(tabId);
                  }}
                >
                  Prompt {tabId}
                  {isTableFormats[promptId] &&
                    dataLengths[promptId] !== undefined && (
                      <span>{`  (${dataLengths[promptId]})`}</span>
                    )}
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
        <TabContent activeTab={activeTab}>
          {promptIds?.map((promptId, index) => (
            <TabPane tabId={`${index + 1}`} key={promptId}>
              <GptPromptTab
                promptId={promptId}
                onTableFormatChange={(isTableFormat) =>
                  this.handleOnTableFormatChange(promptId, isTableFormat)
                }
                onDataLengthChange={(dataLength) =>
                  this.handleDataLengthChange(promptId, dataLength)
                }
              />
            </TabPane>
          ))}
        </TabContent>
      </div>
    );
  }
}

export default GPTPromptTabComponent;
