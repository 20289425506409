import React, { Component } from 'react';

import GptPromptComponent from '../../components/GPTPrompt/GPTPromptComponent';
import { postGPTPrompt } from '../../services/GPTPrompt/GPTPromptService';
import GPTPromptTabComponent from '../../components/GPTPrompt/GPTPromptTabComponent';

interface GptPromptState {
  promptIds: string[];
  error: any;
}

class GptPrompt extends Component<{}, GptPromptState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      promptIds: [],
      error: null,
    };
  }

  save = async (
    sessionId: string,
    prompt: string,
    promptCount: string,
    aiModel: string,
  ) => {
    this.setState({ error: null });
    const response: any | string[] = await postGPTPrompt(
      sessionId,
      prompt,
      promptCount,
      aiModel,
    );

    if (response && response.data && response.data.error) {
      let errorMessage = response.data.error;
      this.setState({ error: errorMessage });
      setTimeout(() => {
        this.setState({ error: null });
      }, 4000);
    } else {
      this.setState({ promptIds: response });
    }
  };

  render() {
    const { promptIds, error } = this.state;

    return (
      <>
        <GptPromptComponent onSave={this.save} />
        {promptIds.length > 0 && (
          <GPTPromptTabComponent promptIds={promptIds} />
        )}
        {error ? <div className="gpt-prompt-error">{error}</div> : ''}
      </>
    );
  }
}

export default GptPrompt;
