import axios, { AxiosResponse } from 'axios';
import { ResponseGenerator } from '../../userservice';
import { urls } from '../../../utilities/urls';
import { endpoints } from '../../../utilities/endpoints';
import { commonheaders } from '../../../utilities/apiConfig';
import {
  IAIAgent,
  ICreateAIAgent,
  IAIAgentRequestFromBackend,
} from '../../../store/AIAgent/interface';
import { IPlaybookCustomBlockData } from '../../../pages/AIAgent/utils/interface';

export async function getAIAgents(
  accountId: String
): Promise<AxiosResponse<IAIAgent[]>> {
  try {
    const response: ResponseGenerator = await axios.get<IAIAgent[]>(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.aiAIAgents}`,
      commonheaders
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function createAIAgent(
  accountId: String,
  agent: ICreateAIAgent
) {
  try {
    return await axios.post(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.aiAIAgents}`,
      {
        ...agent,
      },
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function updateAIAgent(
  accountId: String,
  agentID: number,
  agent: ICreateAIAgent
) {
  try {
    return await axios.put(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.aiAIAgents}/${agentID}`,
      {
        ...agent,
      },
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function deleteAIAgent(accountId: String, agentId: number) {
  try {
    return await axios.delete(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.aiAIAgents}/${agentId}`,
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function sendAIAgentRequest(
  accountID: string,
  agentID: number,
  sessionID: string
): Promise<AxiosResponse<IAIAgentRequestFromBackend>> {
  try {
    return await axios.post(
      `${urls.baseApiURL}${endpoints.accounts}/${accountID}/${
        endpoints.aiAIAgents
      }/${Number(agentID)}/${endpoints.sessions}/${sessionID}/${
        endpoints.sendRequest
      }`,
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function tryAIAgentRequest(
  accountID: string,
  agent: ICreateAIAgent,
  sessionID: string
): Promise<AxiosResponse<IAIAgentRequestFromBackend>> {
  try {
    return await axios.post(
      `${urls.baseApiURL}${endpoints.accounts}/${accountID}/${
        endpoints.aiAgentRequest
      }/${endpoints.sessions}/${sessionID}/${
        endpoints.tryRequest
      }`, {
        ...agent,
      },
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function getAIAgentRequest(
  accountId: String,
  agentId: number,
  requestId: number,
) {
  try {
    return await axios.get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.aiAIAgents}/${agentId}/requests/${requestId}`,
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function getAIAgentRequests(
  accountId: String,
  agentId: number,
): Promise<IAIAgentRequestFromBackend[]> {
  try {
    return await axios.get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.aiAIAgents}/${agentId}/requests`,
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function getAIAgent(
  accountId: String,
  agentId: number,
) {
  try {
    return await axios.get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.aiAIAgents}/${agentId}`,
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function getAllAIAgentTypes(accountId: String) {
  try {
    return await axios.get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.aiAgentTypes}`,
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function generatePlaybookByText(accountId: String, text: string): Promise<IPlaybookCustomBlockData[]>
{
  try {
    let response = await axios.post(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.generatePlaybook}`,
      {
        text,
      },
      commonheaders,
    );

    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}
