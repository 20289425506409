import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  GET_USER_PROFILE,
  CHANGE_CURRENT_ACCOUNT,
  CHANGE_MULTIPLE_ACCOUNT_STATE,
  UPDATE_CURRENT_ACCOUNT,
} from "./actionTypes";
import { ProfileState, ProfileAction } from "./interface";

const initialState: ProfileState = {
  error: "",
  currentuser: {},
  currentaccountselected: {},
  hasMultipleAccounts: false,
};

const profile = (state = initialState, action: ProfileAction) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state };
      break;
    case CHANGE_CURRENT_ACCOUNT:
      state = { ...state, currentaccountselected: action.payload };
      break;
    case CHANGE_MULTIPLE_ACCOUNT_STATE:
      state = {
        ...state,
        hasMultipleAccounts: action.payload.hasMultipleAccounts,
      };
      break;
    case PROFILE_SUCCESS:
      state = {
        ...state,
        currentuser: action.payload.user,
        hasMultipleAccounts: action.payload.hasMultipleAccounts,
      };
      break;
    case PROFILE_ERROR:
      state = { ...state, error: action.payload };
      break;
    case GET_USER_PROFILE:
      state = { ...state };
      break;
    case UPDATE_CURRENT_ACCOUNT:
      state = { ...state, currentaccountselected: action.payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;
