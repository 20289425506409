import React, { Component } from "react";
import { ModalFooter } from "reactstrap";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";

interface ConfirmationModalProps {
  isOpen: boolean;
  title: string;
  handleClose: () => void;
  handleDisable: () => void;
  handleLynesDisable: () => void;
  handleTelavoxDisable: () => void;
  handleCRMServiceDisable: () => void;
}

class ConfirmationModal extends Component<ConfirmationModalProps> {
  disableMethod = (title: string) => {
    const {
      handleDisable,
      handleLynesDisable,
      handleTelavoxDisable,
      handleCRMServiceDisable,
    } = this.props;

    if (title === "Telink") {
      return handleLynesDisable;
    }

    if (title === "Telavox") {
      return handleTelavoxDisable;
    }

    if (title === "CRM Service") {
      return handleCRMServiceDisable;
    }

    return handleDisable;
  }

  render() {
    const {
      isOpen,
      title,
      handleClose,
    } = this.props;

    return (
      <Modal isOpen={isOpen} centered>
        <ModalHeader>Disable {title}</ModalHeader>
        <ModalBody>
          Please be advised that disabling the <strong>{title}</strong>{" "}
          integration will result in the permanent removal of all{" "}
          <b> {title} token and token settings data </b> from your account. This
          action is irreversible. Do you wish to proceed with this action?
        </ModalBody>
        <ModalFooter>
          <Button
            color="red"
            type="submit"
            className="btn-danger"
            onClick={this.disableMethod(title)}
          >
            Yes, disable
          </Button>
          <Button color="blue" type="submit" onClick={handleClose}>
            No, cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ConfirmationModal;
