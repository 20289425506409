import moment from "moment-timezone";
import { countryCodes } from "./utilservice";

const setLocalStorage = (id: any, data: any) => {
  return localStorage.setItem(id, JSON.stringify(data));
};

const getLocalStorage = (id: any) => {
  if (id !== undefined) {
    const value = localStorage.getItem(id);
    if (value !== null) {
      return JSON.parse(localStorage.getItem(id) || "");
    } else {
      return null;
    }
  }
};

const adaptDateToTimezone = (
  dateString: string,
  country?: string,
  outputFormat = "YYYY-MM-DD HH:mm:ss"
): string => {
  const utcMoment = moment.utc(dateString);

  let timezone;
  if (country) {
    const countryWithTimezone = countryCodes.find(
      (countryCode) => countryCode.name.toLowerCase() === country.toLowerCase()
    );

    if (countryWithTimezone) {
      timezone = countryWithTimezone.timezone;
      return utcMoment.tz(timezone).format(outputFormat);
    }
  }

  return utcMoment.format(outputFormat);
};

const adaptDateToUTC = (
  dateString: string,
  outputFormat = "YYYY-MM-DD HH:mm:ss"
): string => {
  const localMoment = moment(dateString);
  return localMoment.utc().format(outputFormat);
};

const limitStringCharacters = (string: string, maxCharacters = 70) => {
  if (string.length > maxCharacters) {
    return string.substring(0, maxCharacters) + "...";
  }

  return string;
};

const formatMinutesToHours = (value: number) => {
  return (value / 60).toFixed(1);
};

const formatSecondsToMinutes = (value: number) => {
  return (value / 60).toFixed(1);
};

const roundValue = (value: number) => {
  return value?.toFixed(0);
};

const roundValueWithOneDecimal = (value: number) => {
  return Number(value).toFixed(1);
};

const toTitleCase = (str: string) => {
  return str.replace(/\b(\w)/g, function(s) {
      return s.toUpperCase();
  });
}

export {
  setLocalStorage,
  getLocalStorage,
  adaptDateToTimezone,
  adaptDateToUTC,
  limitStringCharacters,
  formatMinutesToHours,
  formatSecondsToMinutes,
  roundValue,
  roundValueWithOneDecimal,
  toTitleCase,
};
