import {
  ACCOUNT_ERROR,
  ACCOUNT_SUCCESS,
  EDIT_ACCOUNT,
  GET_ACCOUNT_DETAILS,
  GET_ACCOUNT_DETAILS_SUCCESS,
  SET_ACCOUNT_BRANDING,
  ACCOUNT_BRANDING_SUCCESS,
  ACCOUNT_BRANDING_ERROR,
  DELETE_ACCOUNT_BRANDING_IMAGE,
  GET_ALL_ACCOUNTS,
  GET_ALL_ACCOUNTS_SUCCESS,
  GET_ALL_ACCOUNTS_ERROR,
  CREATE_ACCOUNT,
} from "./actionTypes";
import { IAccount } from "./interface";

export const createAccount = (account: Object) => ({
  type: CREATE_ACCOUNT,
  payload: { account },
})

export const editAccountDetails = (account: IAccount, id: String) => {
  return {
    type: EDIT_ACCOUNT,
    payload: { account, id },
  };
};

export const getAccountDetails = (id: String) => {
  return {
    type: GET_ACCOUNT_DETAILS,
    payload: { id },
  };
};

export const accountSuccess = (msg: String) => {
  return {
    type: ACCOUNT_SUCCESS,
    payload: msg,
  };
};

export const getAccountDetailsSuccess = (accounts: IAccount[]) => {
  return {
    type: GET_ACCOUNT_DETAILS_SUCCESS,
    payload: accounts,
  };
};

export const accountError = (error: String) => {
  return {
    type: ACCOUNT_ERROR,
    payload: error,
  };
};

export const setAccountBranding = (accountId: String, data: Object, isMetting: Boolean) => {
  return {
    type: SET_ACCOUNT_BRANDING,
    payload: { accountId, data, isMetting },
  };
};

export const accountBrandingSuccess = (data: Object) => {
  return {
    type: ACCOUNT_BRANDING_SUCCESS,
    payload: data,
  };
};

export const accountBrandingError = (error: Object | String) => {
  return {
    type: ACCOUNT_BRANDING_ERROR,
    payload: error,
  };
};

export const deleteAccountBrandingImage = (brandingId: String, data: Object, isMetting: Boolean) => {
  return {
    type: DELETE_ACCOUNT_BRANDING_IMAGE,
    payload: { brandingId, data, isMetting },
  };
};

export const getAllAccounts = () => {
  return {
    type: GET_ALL_ACCOUNTS,
  };
};

export const getAllAccountsSuccess = (accounts: IAccount[]) => {
  return {
    type: GET_ALL_ACCOUNTS_SUCCESS,
    payload: accounts,
  };
};

export const getAllAccountsError = (error: Object | String) => {
  return {
    type: GET_ALL_ACCOUNTS_ERROR,
    payload: error,
  };
};
