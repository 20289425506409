import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  GET_USER_PROFILE,
  CHANGE_CURRENT_ACCOUNT,
  CHANGE_MULTIPLE_ACCOUNT_STATE,
  UPDATE_CURRENT_ACCOUNT,
} from "./actionTypes";
import { Icurrentaccountselected } from "./interface";

export const editProfile = (user: Object) => ({
  type: EDIT_PROFILE,
  payload: { user },
});

export const getUserProfile = () => ({
  type: GET_USER_PROFILE,
  payload: null,
});

export const profileSuccess = (
  user: Object,
  hasMultipleAccounts?: Boolean
) => ({
  type: PROFILE_SUCCESS,
  payload: { user, hasMultipleAccounts },
});

export const changeMultipleAccountState = (hasMultipleAccounts: Boolean) => ({
  type: CHANGE_MULTIPLE_ACCOUNT_STATE,
  payload: { hasMultipleAccounts },
});

export const profileError = (error: Object | String) => ({
  type: PROFILE_ERROR,
  payload: error,
});

export const changeCurrentAccount = (account: Object) => ({
  type: CHANGE_CURRENT_ACCOUNT,
  payload: account,
});

export const updateCurrentAccount = (account: Icurrentaccountselected) => ({
  type: UPDATE_CURRENT_ACCOUNT,
  payload: account
});
