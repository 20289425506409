import { all } from "redux-saga/effects";

import RegisterSaga from "./auth/register/saga";
import RegisterMultipleSaga from "./auth/multiple-register/saga";
import SendActivationEmailSaga from "./auth/send-invitation/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import AccountDetailsSaga from "./settings/Account/saga";
import PersonalProfileSaga from "./auth/edit-personal-profile/saga";
import getUserListSaga from "./auth/userlist/saga";
import getAssociatedUsersSaga from "./auth/associated-users/saga";
import getAssociatedTeamsSaga from "./auth/associated-teams/saga";
import getPresentationsSaga from "./Presentations/saga";
import getTeamsSaga from "./settings/Teams/saga";
import getResourcesSaga from "./settings/Resources/saga";
import brandingSaga from "./settings/Branding/saga";
import CalendarSaga from "./settings/Calendars/saga";
import getAnalyticsSaga from "./Analytics/saga";
import rightsSaga from "./Rights/saga";
import fontsSaga from "./Fonts/saga";
import foldersSaga from "./Folders/saga";
import filesSaga from "./Files/saga";
import ReceiversSaga from "./Receivers/saga";
import meetingsSaga from "./Meetings/saga";
import roomsSaga from "./Rooms/saga";
import IntegrationSaga from "./settings/Integration/saga";
import ClearTeamsSaga from "./batch-actions/saga";
import getMeetingTypesSaga from "./settings/MeetingType/saga";
import KeywordSaga from "./keywords/saga";
import SnippetSaga from "./snippets/saga";
import ShareLinkSaga from "./share-links/saga";
import CommentSaga from "./comments/saga";
import GlobalFilterSaga from "./GlobalFilters/saga";
import CallImportSaga from "./CallImports/saga";
import S2AdversusSaga from "./S2AdversusTokens/saga";
import SharedWithMeSaga from "./shared-with-me/saga";
import ChatSaga from "./Chat/saga";
import AccountTokensSaga from './settings/AccountTokens/saga';
import TelavoxTokenSaga from './settings/Telavox/saga';
import AIAgentSaga from './AIAgent/saga';
import DashboardSaga from './Dashboard/saga';

export default function* rootSaga() {
  yield all([
    RegisterSaga(),
    RegisterMultipleSaga(),
    SendActivationEmailSaga(),
    AuthSaga(),
    ForgetSaga(),
    ProfileSaga(),
    LayoutSaga(),
    AccountDetailsSaga(),
    PersonalProfileSaga(),
    getUserListSaga(),
    getAssociatedUsersSaga(),
    getAssociatedTeamsSaga(),
    getPresentationsSaga(),
    getTeamsSaga(),
    getResourcesSaga(),
    getAnalyticsSaga(),
    rightsSaga(),
    fontsSaga(),
    foldersSaga(),
    meetingsSaga(),
    brandingSaga(),
    CalendarSaga(),
    filesSaga(),
    ReceiversSaga(),
    roomsSaga(),
    IntegrationSaga(),
    ClearTeamsSaga(),
    getMeetingTypesSaga(),
    KeywordSaga(),
    SnippetSaga(),
    ShareLinkSaga(),
    CommentSaga(),
    GlobalFilterSaga(),
    CallImportSaga(),
    S2AdversusSaga(),
    SharedWithMeSaga(),
    ChatSaga(),
    AccountTokensSaga(),
    TelavoxTokenSaga(),
    AIAgentSaga(),
    DashboardSaga(),
  ]);
}
