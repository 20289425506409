import {
  GET_ACCOUNT_TOKENS,
  GET_ACCOUNT_TOKENS_SUCCESS,
  GET_ACCOUNT_TOKENS_ERROR,
  CREATE_ACCOUNT_TOKEN,
  CREATE_ACCOUNT_TOKEN_SUCCESS,
  CREATE_ACCOUNT_TOKEN_ERROR,
  EDIT_ACCOUNT_TOKEN,
  EDIT_ACCOUNT_TOKEN_SUCCESS,
  EDIT_ACCOUNT_TOKEN_ERROR,
  DELETE_ACCOUNT_TOKEN,
  DELETE_ACCOUNT_TOKEN_SUCCESS,
  DELETE_ACCOUNT_TOKEN_ERROR,
  ENABLE_LYNES_INTEGRATION,
  ENABLE_LYNES_INTEGRATION_SUCCESS,
  ENABLE_LYNES_INTEGRATION_ERROR,
  DISABLE_LYNES_INTEGRATION,
  DISABLE_LYNES_INTEGRATION_SUCCESS,
  DISABLE_LYNES_INTEGRATION_ERROR,
  ENABLE_CRM_SERVICE_INTEGRATION,
  ENABLE_CRM_SERVICE_INTEGRATION_SUCCESS,
  ENABLE_CRM_SERVICE_INTEGRATION_ERROR,
  DISABLE_CRM_SERVICE_INTEGRATION,
  DISABLE_CRM_SERVICE_INTEGRATION_SUCCESS,
  DISABLE_CRM_SERVICE_INTEGRATION_ERROR,
  SET_CRM_SERVICE_INIT_STATE,
} from './actionTypes';
import { IAccountToken, IAuthenticationData, ICreateAccountToken, IEnableCRMServiceIntegration } from './interface';

export const getAccountTokens = (accountId: String) => ({
  type: GET_ACCOUNT_TOKENS,
  payload: { accountId },
});

export const getAccountTokensSuccess = (tokens: IAccountToken[]) => ({
  type: GET_ACCOUNT_TOKENS_SUCCESS,
  payload: tokens,
});

export const getAccountTokensError = (error: String | Object) => ({
  type: GET_ACCOUNT_TOKENS_ERROR,
  payload: error,
});

export const createAccountToken = (
  accountId: String,
  token: ICreateAccountToken
) => ({
  type: CREATE_ACCOUNT_TOKEN,
  payload: { accountId, token },
});

export const createAccountTokenSuccess = (token: IAccountToken) => ({
  type: CREATE_ACCOUNT_TOKEN_SUCCESS,
  payload: token,
});

export const createAccountTokenError = (error: String | Object) => ({
  type: CREATE_ACCOUNT_TOKEN_ERROR,
  payload: error,
});

export const editAccountToken = (accountId: String, token: IAccountToken) => ({
  type: EDIT_ACCOUNT_TOKEN,
  payload: { accountId, token },
});

export const editAccountTokenSuccess = (token: IAccountToken) => ({
  type: EDIT_ACCOUNT_TOKEN_SUCCESS,
  payload: token,
});

export const editAccountTokenError = (error: String | Object) => ({
  type: EDIT_ACCOUNT_TOKEN_ERROR,
  payload: error,
});

export const deleteAccountToken = (tokenId: string, accountId: String) => ({
  type: DELETE_ACCOUNT_TOKEN,
  payload: { tokenId, accountId },
});

export const deleteAccountTokenSuccess = (tokenId: string) => ({
  type: DELETE_ACCOUNT_TOKEN_SUCCESS,
  payload: tokenId,
});

export const deleteAccountTokenError = (error: String | Object) => ({
  type: DELETE_ACCOUNT_TOKEN_ERROR,
  payload: error,
});

export const enableLynesIntegration = (accountId: String) => ({
  type: ENABLE_LYNES_INTEGRATION,
  payload: accountId,
})

export const enableLynesIntegrationSuccess = (authenticationData: IAuthenticationData) => ({
  type: ENABLE_LYNES_INTEGRATION_SUCCESS,
  payload: authenticationData,
})

export const enableLynesIntegrationError = (error: string | Object) => ({
  type: ENABLE_LYNES_INTEGRATION_ERROR,
  payload: error,
})

export const disableLynesIntegration = (accountId: String) => ({
  type: DISABLE_LYNES_INTEGRATION,
  payload: accountId,
})

export const disableLynesIntegrationSuccess = () => ({
  type: DISABLE_LYNES_INTEGRATION_SUCCESS,
})

export const disableLynesIntegrationError = (error: string | Object) => ({
  type: DISABLE_LYNES_INTEGRATION_ERROR,
  payload: error,
})

export const enableCRMServiceIntegration = (payload: IEnableCRMServiceIntegration) => ({
  type: ENABLE_CRM_SERVICE_INTEGRATION,
  payload: payload,
})

export const enableCRMServiceIntegrationSuccess = (data: any) => ({
  type: ENABLE_CRM_SERVICE_INTEGRATION_SUCCESS,
  payload: data,
});

export const enableCRMServiceIntegrationError = (message: string | Object) => ({
  type: ENABLE_CRM_SERVICE_INTEGRATION_ERROR,
  payload: message,
});

export const disableCRMServiceIntegration = () => ({
  type: DISABLE_CRM_SERVICE_INTEGRATION,
});

export const disableCRMServiceIntegrationSuccess = (data: any) => ({
  type: DISABLE_CRM_SERVICE_INTEGRATION_SUCCESS,
  payload: data,
});

export const disableCRMServiceIntegrationError = (message: string | Object) => ({
  type: DISABLE_CRM_SERVICE_INTEGRATION_ERROR,
  payload: message,
});

export const setCRMServiceInitialState = () => ({
  type: SET_CRM_SERVICE_INIT_STATE,
});