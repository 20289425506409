import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { History, Location } from 'history';
import { logoutUser } from "../../services/auth/authservice";

type logoutProps = {
    history: History;
    location: Location;
    logoutUser: () => void;
};

class Logout extends Component<logoutProps> {
    componentDidMount = () => {
         logoutUser();
         
    };

    render() {
        return <React.Fragment></React.Fragment>;
    }
}


export default withRouter(connect(null, { logoutUser })(Logout));
