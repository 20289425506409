import React, { Component } from 'react';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { aiModelOptions } from '../../pages/GptPrompt/interface';

interface GptPromptComponentProps {
  onSave: (
    sessionId: string,
    prompt: string,
    promptCount: string,
    aiModel: string
  ) => void;
}

interface GptPromptComponentState {
  sessionId: string;
  prompt: string;
  promptCount: string;
  aiModel: string;
}

class GptPromptComponent extends Component<
  GptPromptComponentProps,
  GptPromptComponentState
> {
  constructor(props: GptPromptComponentProps) {
    super(props);
    this.state = {
      sessionId: '',
      prompt: '',
      promptCount: '1',
      aiModel: aiModelOptions[0].value,
    };
  }

  handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (
      name === 'sessionId' ||
      name === 'prompt' ||
      name === 'promptCount' ||
      name === 'aiModel'
    ) {
      this.setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { sessionId, prompt, promptCount, aiModel } = this.state;
    const { onSave } = this.props;

    onSave(sessionId, prompt, promptCount, aiModel);
  };

  render() {
    const { sessionId, prompt, promptCount, aiModel } = this.state;

    return (
      <div className="gpt-prompt-page">
        <div className="gpt-prompt-header">
          <h1 className="gpt-prompt-title">GPT Prompt</h1>
          <AvForm>
            <Label htmlFor="aiModel">Choose AI model</Label>
            <AvField
              value={aiModel}
              type="select"
              name="aiModel"
              onChange={this.handleChange}
            >
              {aiModelOptions.map((aiModel) => {
                return (
                  <option
                    key={aiModel.value}
                    className="ai-model-option-item"
                    value={aiModel.value}
                  >
                    {aiModel.label}
                  </option>
                );
              })}
            </AvField>
          </AvForm>
        </div>
        <Form onSubmit={this.handleSubmit}>
          <FormGroup>
            <Label for="session">Session</Label>
            <Input
              type="text"
              name="sessionId"
              id="session"
              placeholder="Enter session..."
              value={sessionId}
              onChange={this.handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="prompt">Prompt</Label>
            <Input
              type="textarea"
              name="prompt"
              id="prompt"
              className="prompt-textarea"
              placeholder="Enter prompt..."
              value={prompt}
              onChange={this.handleChange}
              required
            />
          </FormGroup>
          <div className="prompt-count-box">
            <Button type="submit">Run</Button>
            <Input
              type="number"
              id="promptCount"
              className="prompt-count-input"
              value={promptCount}
              onChange={this.handleChange}
              name="promptCount"
              min="1"
            />
            <Label for="promptCount" className="prompt-count-label">
              times
            </Label>
          </div>
        </Form>
      </div>
    );
  }
}

export default GptPromptComponent;
