export const endpoints = {
  getUsers: "users",
  getAuthToken: "login",
  forgotPassword: "reset-password",
  getGoogleLoginAuthToken: "login/google/check",
  getZohoCheck: "zoho/login/check",
  salesforceAuthSuccess: "salesforce/auth-success",
  getDynamicsCheck: "dynamics365/login/check",
  getGoogleDriveLoginCheck: "drive/login/check",
  getGoogleDriveFiles: "drive",
  getGoogleDownload: "drive/download",
  loginWithGoogle: "login/google",
  loginWithGoogleDrive: "drive/login",
  verifyUser: "me",
  getPresentations: "presentations",
  setPresentations: "presentations",
  duplicatePresentation: "duplicate",
  removePresentations: "presentations",
  editUserProfile: "users",
  changeUserProfilePassword: "change-password",
  createUserProfile: "users",
  files: "files",
  fileNotes: "file-notes",
  getAccountDetails: "users",
  editAccountDetails: "accounts",
  register: "register",
  getTeams: "accounts",
  createTeam: "teams",
  addSlides: "slides",
  account: "account",
  accounts: "accounts",
  getSalesAnalytics: "performed_presentations",
  pipedrive: "pipedrive",
  import: "import",
  analytics: "analytics",
  categories: "categories",
  rights: "rights",
  bySession: "by_session",
  managedTeam: "managed_teams",
  manager: "manager",
  role: "role",
  font: "font",
  daily: "daily",
  byDay: "by_day",
  brand: "brand",
  branding: "branding",
  loginAs: "login_as",
  shareLinkLogin: "share-link-user-login",
  resources: "resources",
  folders: "folders",
  move: "move",
  receivers: "receivers",
  deleterecordings: "recordings",
  recordings: "recordings/sessions",
  userFolder: "folders/user",
  accountRights: "account_rights",
  loginSessions: "login_sessions",
  microsoftIntegration: "integrations/microsoft-dynamics",
  duplicateRoom: "duplicate",
  timeline: "timeline",
  comments: "comments",
  create: "create",
  sessions: "sessions",
  session: "session",
  meetingAIAnalytics: "meeting-ai-analytics",
  meetingTypes: "meeting-types",
  meetingType: "meeting-type",
  campaigns: "campaigns",
  defaultMeetingTypes: "default-meeting-types",
  // Insights
  stats: "stats",
  analyticStats: "analytic-stats",
  user: "user",
  userDaily: "user_daily",
  team: "team",
  teamDaily: "team_daily",
  meetings: "meetings",
  meetingsDaily: "meetings_daily",
  meetingsLive: "meetings_live",
  rooms: "rooms",
  roomsDaily: "rooms_daily",
  roomSessions: "room_sessions",
  roomSessionsDaily: "room_sessions_daily",
  participants: "participants",
  participantsDaily: "participants_daily",
  members: "members",
  membersDaily: "members_daily",
  contacts: "contacts",
  contactsDaily: "contacts_daily",
  companies: "companies",
  companiesDaily: "companies_daily",
  documents: "documents",
  documentsDaily: "documents_daily",
  pages: "pages",
  pagesDaily: "pages_daily",
  interactions: "interactions",
  interactionsDaily: "interactions_daily",
  view: "view",
  widgetLayouts: "widget_layouts",
  logs: "logs",
  integration: "integration",
  transcript: "transcript",
  snippets: "snippets",
  transcriptRequest: "transcript/request",
  transcriptSpeakersRequest: "transcript/parse",
  sentence: "sentence",
  keywordGroup: "keyword-group",
  dashboard: "dashboard",
  aiCategories: "categories",
  calendars: "calendars",
  checkFutureEvents: "check_future_events",
  meetingBotsEvent: "meeting-bot-events",
  transcriptEvent: "transcript-events",
  recordingDetails: "recording-details",
  recordingTags: "recordings",
  globalFilters: "global-filters",
  callTokens: "call-tokens",
  callImportSettings: "call-import-settings",
  taggedRecordings: "tagged-recordings",
  deals: "deals",
  update_meeting: "update-meeting",
  aiChatSession: "ai",
  prompt: "prompt",
  callUserToken: "call-user-tokens",
  callImports: "call-imports",
  recordingsAnalytics: "recordings",
  recordingsWithTranscription: "recordings-with-transcription",
  accountTokens: "account-tokens",
  lynesEnable: "lynes-enable",
  lynesDisable: "lynes-disable",
  chatGPT: "chat-gpt",
  customPrompt: "custom-prompt",
  telavoxToken: "telavox",
  sessionSummary: "session-summary",
  adversusRule: "adversus-import-settings-rule",
  crmService: "crm-service",
  enable: "enable",
  disable: "disable",
  aiAIAgents: "ai-agents",
  aiAgentRequest: "ai-agent-request",
  sendRequest: "send-request",
  tryRequest: "try-request",
  aiAgentTypes: "ai-agent-types",
  agentStats: "agent-stats",
  meetingCalendarSyncInfo: "calendar-sync-info",
  generatePlaybook: "generate-playbook",
};
